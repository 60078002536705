import { useEffect, useState } from "react";

// DISPLAY COMPONENTS
import { DnDContainer } from "./DnDContainer";
import { useStore } from "@/stores/useStore";
import { Layout } from "@/types/v2";

export const Dashboard = () => {
  const logout = useStore((s) => s.logout);
  const {
    dashboardServiceWriterId,
    dashboardTechnicianId,
    dashboardLayoutId: selectedLayoutId,
    syncWithSource,
    layouts: layoutsData,
  } = useStore();

  // LAYOUT MANAGEMENT
  const [selectedLayout, setSelectedLayout] = useState<Layout[]>();
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((t) => t + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // SUBSCRIBE TO FIRESTORE SHOP QUERY TO RETRIEVE CONTAINERS AND LAYOUTS INFO
  // Did this on App.jsx

  useEffect(() => {
    if (
      selectedLayoutId &&
      layoutsData &&
      layoutsData.length > 0 &&
      Object.keys(layoutsData).includes(selectedLayoutId)
    ) {
      const layout = layoutsData[selectedLayoutId as keyof typeof layoutsData];
      const layoutName = Object.keys(layout)[0];
      setSelectedLayout(layout[layoutName as keyof typeof layout]);
    }
  }, [layoutsData, selectedLayoutId]);

  const handleSignOut = () => {
    logout();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      syncWithSource();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [syncWithSource]);

  if (layoutsData === undefined) {
    return (
      <>
        <div>
          Loading Layouts. If this action takes too long. Click the button below
          and refresh the page.
        </div>
        <button
          style={{
            marginLeft: 8,
            width: 180,
            padding: 8,
            backgroundColor: "#244C91",
            color: "#ffffff",
            borderRadius: 10,
          }}
          onClick={handleSignOut}
        >
          Logout
        </button>
      </>
    );
  }

  return (
    <div>
      {selectedLayout ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(96, 1fr)`,
            gridTemplateRows: `repeat(12,1fr)`,
            gap: ".5rem",
            height: "calc(100vh - 92px)",
          }}
        >
          <DnDContainer
            selectedLayout={selectedLayout}
            serviceAdvisorId={dashboardServiceWriterId}
            technicianId={dashboardTechnicianId}
            ticker={ticker}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center text-3xl mt-20">
          <p>Please select a layout to display</p>
        </div>
      )}
    </div>
  );
};
