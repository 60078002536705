import { store } from "@/stores/useStore";
import clone from "just-clone";
import { shop } from "./shop";

export const container = {
  updateContainerName: (id: string, displayName: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].displayName = displayName;
    shop.update(shopId, { containers: containers });
  },

  updateContainerTechnician: (id: string, technicianId: string | string[]) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].linkedTechnicianId = technicianId;
    shop.update(shopId, { containers: containers });
  },
  deleteContainerTechnician: (id: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    delete containers[containerIndex].linkedTechnicianId;
    shop.update(shopId, { containers: containers });
  },

  updateContainerLabel: (id: string, label: string | string[]) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].linkedSourceLabel = label;
    shop.update(shopId, { containers: containers });
  },
  deleteContainerLabel: (id: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    delete containers[containerIndex].linkedSourceLabel;
    shop.update(shopId, { containers: containers });
  },
};
