import { ReactNode } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import LayoutContainer from "./LayoutContainer";
import "../../../node_modules/react-grid-layout/css/styles.css";

const ReactGridLayout = WidthProvider(RGL);

interface GridLayoutProps {
  layout: (RGL.Layout & { children?: ReactNode })[];
  onLayoutChange: (layout: RGL.Layout[]) => void;
  removeItem: any;
}

const GridLayout = (props: GridLayoutProps) => {
  return (
    <ReactGridLayout
      layout={props.layout}
      onLayoutChange={props.onLayoutChange}
      cols={96}
      rowHeight={40}
      isResizable={true}
    >
      {props.layout.map(({ i, children, ...restProps }) => {
        return (
          <LayoutContainer
            removeItem={props.removeItem}
            key={i}
            id={i}
            {...restProps}
          />
        );
      })}
    </ReactGridLayout>
  );
};

export default GridLayout;
