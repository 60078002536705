import { LocalStorageBaseKey } from "@/types/v2/LocalStorage";

export const localStorage = {
  get: (key: LocalStorageBaseKey, shopId?: string) => {
    return window.localStorage.getItem(localStorage.generateKey(key, shopId));
  },
  set: (
    key: LocalStorageBaseKey,
    value: string | undefined,
    shopId?: string
  ) => {
    return window.localStorage.setItem(
      localStorage.generateKey(key, shopId),
      value ?? ""
    );
  },
  generateKey: (key: LocalStorageBaseKey, shopId?: string) => {
    if (shopId) {
      return `${key}-${shopId}`;
    }
    return key;
  },
};
