import { auth } from "@/services/auth";
import { BaseFirestore } from "@/types/v2";

export const addUpdateData = <T>(data: T) => {
  return {
    ...data,
    _source: "client",
    updatedAt: new Date(),
    updatedBy: auth.currentUser?.uid ?? "UNKNOWN",
  };
};
export const addCreationData = <T>(
  data: T,
  creationSource: BaseFirestore["_creationSource"]
) => {
  return {
    ...addUpdateData(data),
    _creationSource: creationSource || "client",
    _deleted: false,
    createdAt: new Date(),
    createdBy: auth.currentUser?.uid ?? "UNKNOWN",
  };
};
