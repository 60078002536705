import { firestore } from "@/services/firestore";
import { CardContents, Container, Shop } from "@/types/v2";
import { Shop as ShopV1 } from "@/types/v1";
import { generic } from "./generic";
import { createConverter } from "@/models/common";
import {
  DEFAULT_CARD_PRIMARY_FIELD,
  DEFAULT_CARD_SECONDARY_FIELD,
  DEFAULT_CARD_TERTIARY_FIELD,
} from "@/lib/const";

export const shop = {
  ref: (shopId: string) => firestore.getRef<Shop>(`shops/${shopId}`),
  listShopsByUser: async (userId: string): Promise<Shop[]> => {
    const shops = await firestore.queryDocuments<Shop>(
      "shops",
      "users",
      "array-contains",
      userId,

      createConverter<ShopV1, Shop>(shop)
    );
    const asShop = await firestore.getDocument<Shop>(
      `shops/${userId}`,
      createConverter<ShopV1, Shop>(shop)
    );
    if (asShop) shops.push(asShop);

    return shops;
  },
  listShops: async () => {
    return firestore.listDocuments<Shop>(
      "shops",
      createConverter<ShopV1, Shop>(shop)
    );
  },
  subscribeShop: (id: string, callback: (doc: Shop | undefined) => void) => {
    return firestore.subscribeDocument<Shop>(
      `shops/${id}`,
      callback,
      createConverter<ShopV1, Shop>(shop)
    );
  },
  update: async (shopId: string, shopData: Partial<Shop>) => {
    return generic.update({ id: shopId, ...shopData }, `shops/${shopId}`);
  },
  setStatuses: async (shopId: string, statuses: string[]) => {
    return shop.update(shopId, { statusesToSync: statuses });
  },
  isV1: (shop: ShopV1 | Shop): shop is ShopV1 => {
    return (shop as Shop).schemaVersion === undefined;
  },
  transformV1ToV2: (shopData: ShopV1): Shop => {
    const returnShop = {
      _isSoftMigrated: true,
      containers: shopData.containers,
      createdOn: shopData.createdOn,
      defaultTimer: shopData.defaultTimer,
      email: shopData.email,
      enabledFeatures: shopData.enabledFeatures,
      lastSyncedTimestamp: shopData.lastSyncedTimestamp,
      layouts: shopData.layouts,
      name: shopData.name,
      phone: shopData.phone,
      refreshTimeInSeconds: shopData.refreshTimeInSeconds ?? 300,
      repairOrders: shopData.repairOrders ?? [],
      shopId: shopData.shopId,
      id: shopData.shopId,
      schemaVersion: 2,
      statusesToSync: shopData.statuses.map((status) => `${status}`),
      cardConfig: {
        cardContents: {
          primaryField: (shopData.cardContents?.primaryField ??
            DEFAULT_CARD_PRIMARY_FIELD) as CardContents["primaryField"],
          secondaryField: (shopData.cardContents?.secondaryField ??
            DEFAULT_CARD_SECONDARY_FIELD) as CardContents["secondaryField"],
          tertiaryField: (shopData.cardContents?.tertinaryField ??
            DEFAULT_CARD_TERTIARY_FIELD) as CardContents["tertiaryField"],
        },
      },
      integrationKey: "prod",
      integration: "tekmetric",
    };
    const updateShop = { ...returnShop } as Shop;
    delete updateShop._isSoftMigrated;

    shop.update(returnShop.shopId, updateShop);
    return returnShop as Shop;
  },
  updateCardContent: async (
    shopId: string,
    fieldName: string,
    fieldValue: string,
    cardContents: Shop["cardConfig"]["cardContents"],
    shopData?: Shop
  ) => {
    if (!shopData) {
      shopData = await shop.get(shopId);
    }
    if (!shopData) return;
    return shop.update(shopId, {
      cardConfig: {
        ...shopData.cardConfig,
        cardContents: { ...cardContents, [fieldName]: fieldValue },
      },
    });
  },
  updateTimerUnits: async (
    shopId: string,
    timerUnits: Shop["cardConfig"]["timerUnits"],
    shopData?: Shop
  ) => {
    if (!shopData) {
      shopData = await shop.get(shopId);
    }
    if (!shopData) return;
    return shop.update(shopId, {
      cardConfig: { ...shopData.cardConfig, timerUnits },
    });
  },
  updateDefaultTimerValue: async (shopId: string, defaultTimer: string) => {
    return shop.update(shopId, { defaultTimer: defaultTimer });
  },
  updateCardLabelWrap: async (shopId: string, cardLabelWrap: string) => {
    return shop.update(shopId, { cardLabelWrap: cardLabelWrap });
  },
  get: (shopId: string) => {
    return firestore.getDocument<Shop>(
      `shops/${shopId}`,
      createConverter<ShopV1, Shop>(shop)
    );
  },
  addCardToContainer: async (
    shopId: string,
    containerId: string,
    cardId: string
  ) => {
    const shopData = await shop.get(shopId);

    if (!shopData) {
      throw new Error(`Shop ${shopId} not found`);
    }

    const containers = shopData.containers;

    const containerIndex = containers.findIndex(
      (container) => container.id === containerId
    );

    if (containerIndex === -1) {
      throw new Error(`Container ${containerId} not found for shop ${shopId}`);
    }

    // Remove card from old container
    const oldContainers = locateCardContainers(containers, cardId);
    oldContainers.forEach((oldContainerId) => {
      const oldContainer = containers.find(
        (container) => container.id === oldContainerId
      );
      if (oldContainer) {
        oldContainer.tags = oldContainer.tags.filter((tag) => tag !== cardId);
        containers[containers.indexOf(oldContainer)] = oldContainer;
      }
    });

    const container = containers[containerIndex];
    container.tags.push(cardId);
    containers[containerIndex] = container;

    return shop.update(shopId, {
      containers: containers,
    });
  },
  removeCardFromContainers: async (shopId: string, cardId: string) => {
    const shopData = await shop.get(shopId);

    if (!shopData) {
      throw new Error(`Shop ${shopId} not found`);
    }

    const containers = shopData.containers;

    // Remove card from old container
    const oldContainers = locateCardContainers(containers, cardId);
    oldContainers.forEach((oldContainerId) => {
      const oldContainer = containers.find(
        (container) => container.id === oldContainerId
      );
      if (oldContainer) {
        oldContainer.tags = oldContainer.tags.filter((tag) => tag !== cardId);
        containers[containers.indexOf(oldContainer)] = oldContainer;
      }
    });

    return shop.update(shopId, {
      containers: containers,
    });
  },
};

const locateCardContainers = (containers: Container[], cardId: string) => {
  const cardContainers: string[] = [];

  for (const container of containers) {
    if (container.tags.includes(cardId)) {
      cardContainers.push(container.id);
    }
  }

  return cardContainers;
};
