import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { app } from "./app";
import { config } from "@/config";

const db = getFirestore(app);

if (config.emulator) {
  connectFirestoreEmulator(db, "localhost", 8080);
}

export { db };
