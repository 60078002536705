import { PencilIcon, SaveIcon, UndoIcon } from "lucide-react";
import { Button } from "../Base/Button";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "@/lib/utils";

export interface EditSaveButtonsProps {
  isEditing: boolean;
  handleSave: () => void;
  handleDiscard: () => void;
  handleEdit: () => void;
  saveDisabled?: boolean;
  discardDisabled?: boolean;
  editDisabled?: boolean;
  containerClassName?: ClassNameValue;
}

export const EditSaveButtons = ({
  isEditing,
  handleSave,
  handleDiscard,
  handleEdit,
  saveDisabled,
  discardDisabled,
  editDisabled,
  containerClassName,
}: EditSaveButtonsProps) => {
  return (
    <div className={cn("flex flex-row gap-2", containerClassName)}>
      {isEditing ? (
        <>
          <Button
            disabled={saveDisabled}
            variant="ghost"
            size="icon"
            onClick={handleSave}
          >
            <SaveIcon />
          </Button>
          <Button
            disabled={discardDisabled}
            variant="ghost"
            size="icon"
            onClick={handleDiscard}
          >
            <UndoIcon />
          </Button>
        </>
      ) : (
        <Button
          disabled={editDisabled}
          variant="ghost"
          size="icon"
          onClick={handleEdit}
        >
          <PencilIcon />
        </Button>
      )}
    </div>
  );
};
