import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { sentry } from "./services/sentry";

sentry.init();

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(<App />);
