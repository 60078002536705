import { Button } from "@/components/Base/Button";
import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";
import { Heading } from "@/components/Common/Heading";
import { config } from "@/config";
import { useIsSuperAdmin } from "@/hooks/useIsSuperAdmin";
import {
  FEATURE_FLAG,
  FEATURE_FLAG_OPTIONS,
  featureFlags,
} from "@/models/featureFlags";
import { FeatureFlags } from "@/types/v1";
import { useEffect, useState } from "react";

export const GlobalConfigPage = () => {
  const [initialized, isSuperAdmin] = useIsSuperAdmin();
  const [features, setFeatures] = useState<FeatureFlags>();

  useEffect(() => {
    if (initialized && isSuperAdmin) {
      featureFlags.list().then((data) => setFeatures(data));
    }
  }, [initialized, isSuperAdmin]);

  if (!initialized) return <div>Loading...</div>;
  if (!isSuperAdmin) return <div>403</div>;

  console.log(Object.keys(config.availableFeatureFlags));

  const enabledForAllUsers = (ff: FEATURE_FLAG) => {
    if (!features) return false;
    if (!features[ff]) return false;
    return features[ff].allUsers;
  };

  const handleConfigSwitch = async (enabled: boolean, ff: FEATURE_FLAG) => {
    await featureFlags.update(ff, {
      testUsers: features ? features[ff].testUsers : [],
      allUsers: enabled,
    });

    // Mutually Exclusive
    const mutuallyExclusiveWith =
      FEATURE_FLAG_OPTIONS[ff].mutuallyExclusiveWith;

    if (mutuallyExclusiveWith) {
      console.log(`mutuallyExclusiveWith ${mutuallyExclusiveWith}`);
      // Opposite for the mutually exclusive
      await featureFlags.update(mutuallyExclusiveWith, {
        testUsers: features ? features[mutuallyExclusiveWith].testUsers : [],
        allUsers: !enabled,
      });
    }

    // Exclusive With
    const exclusiveWith = FEATURE_FLAG_OPTIONS[ff].exclusiveWith;

    if (exclusiveWith) {
      console.log(`exclusiveWith ${exclusiveWith}`);
      // Opposite for the exclusive, but no need to enable if disabling this one
      await featureFlags.update(exclusiveWith, {
        testUsers: features ? features[exclusiveWith].testUsers : [],
        allUsers: false,
      });
    }

    featureFlags.list().then((data) => setFeatures(data));
  };

  const handleConfigAdd = (ff: string) => {
    featureFlags
      .update(ff, {
        testUsers: [],
        allUsers: false,
      })
      .then(() => featureFlags.list())
      .then((data) => setFeatures(data));
  };

  return (
    <div className="p-5">
      <Heading>Global Config</Heading>
      <div className="rounded-xl border border-primary-foreground max-w-screen-md flex flex-col items-center gap-4 p-4">
        {Object.keys(config.availableFeatureFlags)
          .filter((ff) =>
            features ? Object.keys(features).includes(ff) : false
          )
          .map((ff: FEATURE_FLAG, index) => (
            <EnableDisableSwitch
              key={`${ff}-${index}`}
              label={FEATURE_FLAG_OPTIONS[ff].label}
              enabled={enabledForAllUsers(ff)}
              disabled={!FEATURE_FLAG_OPTIONS[ff].canEnableGlobally}
              onChange={(enabled) => handleConfigSwitch(enabled, ff)}
            />
          ))}
        {Object.keys(config.availableFeatureFlags)
          .filter((ff) =>
            features ? !Object.keys(features).includes(ff) : true
          )
          .map((ff: FEATURE_FLAG, index) => (
            <div
              key={`${ff}-${index}`}
              className="flex flex-row justify-between gap-2 w-96 items-center"
            >
              <div>{FEATURE_FLAG_OPTIONS[ff].label}</div>
              <div className="flex flex-row justify-between gap-2 items-center">
                <Button
                  onClick={() => handleConfigAdd(ff)}
                  variant={"secondary"}
                >
                  Add to Database
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
