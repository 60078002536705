import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from "lucide-react";
import { Column } from "@tanstack/react-table";
import { Button } from "@/components/Base/Button";

type HeaderSortableProps<T> = {
  column: Column<T, unknown>;
  title: string;
};

export const HeaderSortable = <T,>({
  column,
  title,
}: HeaderSortableProps<T>) => {
  return (
    <Button
      variant="accent"
      onClick={() => {
        if (column.getIsSorted() === "asc") {
          column.toggleSorting(true, true);
        } else if (column.getIsSorted() === "desc") {
          column.clearSorting();
        } else {
          column.toggleSorting(false, true);
        }
      }}
    >
      <span className="mr-2">{title}</span>
      {column.getIsSorted() === "desc" ? (
        <ArrowDownIcon size={14} />
      ) : column.getIsSorted() === "asc" ? (
        <ArrowUpIcon size={14} />
      ) : (
        <ArrowUpDownIcon size={14} />
      )}
    </Button>
  );
};
