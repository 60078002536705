import React, { useEffect, useState } from "react";
import { SHDBHeader } from "../Header/SHDBHeader";
import { currentShopId } from "../../data/Context/shop";
import { useStore } from "../../stores/useStore";

export const Users = (props) => {
  const shopId = currentShopId.use();
  const [shopInfo, setShopInfo] = useState(null);

  const shopData = useStore((s) => s.shop);

  useEffect(() => {
    if (shopData) {
      // console.log("in CompanyInfo. shopData = ", shopData)
      const shopInfo = shopData;
      setShopInfo(shopInfo);
    }
  }, [shopData]);

  return (
    <>
      <SHDBHeader
        logoExists={props.logoExists}
        logoUrl={props.logoUrl}
        shopId={props.shopId}
      />
      <div
        style={{
          backgroundColor: "#202124",
          color: "#ffffff",
          height: "100vh",
          padding: "20px 120px",
        }}
      >
        <h1 style={{ fontSize: 24 }}>Users</h1>

        {shopInfo ? (
          <div>
            <div>Company Name: {shopInfo?.name}</div>
            <div>Company Email: {shopInfo?.email}</div>
            <div>Company Phone: {shopInfo?.phone}</div>
            <div>Company Website: {shopInfo?.website}</div>
            <div>Shop ID: {shopInfo?.shopId}</div>
            <div>Account Creation Date: {shopInfo?.createdOn}</div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};
