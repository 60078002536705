import { Timestamp } from "firebase/firestore";
import { BaseFirestore } from "./Firestore";

export const EmployeeRole = [
  "admin",
  "office",
  "technician",
  "viewOnly",
] as const;
export type EmployeeRole = (typeof EmployeeRole)[number];
export type Employee = BaseFirestore & {
  schemaVersion: 2;
  disabled?: boolean;
  email: string;
  shopHeroRole?: EmployeeRole | null;
  shopHeroAuthUid?: string;
  firstName: string;
  lastName: string;
  id: string;
  hideInShopHero?: boolean;
  active?: boolean;
  sourceLastUpdated: Date | Timestamp;
};

export type CreateEmployeeDto = Pick<
  Employee,
  "firstName" | "lastName" | "email" | "sourceLastUpdated"
> & { schemaVersion: number };
