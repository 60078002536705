import { useState } from "react";
import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { CardForm, CardFormProps } from "./CardForm";
import { PatchCardDto } from "@/types/v2";

interface CardFormDialogProps<T> extends CardFormProps<T> {
  title?: string;
  buttonTitle?: string;
  cardData?: PatchCardDto;
  description?: string;
}
export const CardFormDialog = <T,>({
  title,
  buttonTitle,
  cardData,
  onSubmit,
  description,
}: CardFormDialogProps<T>) => {
  const [open, setOpen] = useState(false);

  const handleSave = async (values: T) => {
    return onSubmit(values).finally(() => setOpen(false));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>
          {buttonTitle ?? "Create Card"}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title ?? "Create a New Card"}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {description ??
            "Create a new card. Fields here are determined by the card configuration."}
        </DialogDescription>
        <div className="flex flex-col gap-2">
          <CardForm
            cancelButton={
              <Button
                type="button"
                variant={"secondary"}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            }
            submitButton={<Button type="submit">Save</Button>}
            cardData={cardData}
            onSubmit={handleSave}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
