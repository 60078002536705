import { ColumnDef } from "@tanstack/react-table";
import { Fragment, ReactNode } from "react";
import { Employee, EmployeeRole } from "@/types/v2";
import { HeaderSortable } from "@/components/Base/HeaderSortable";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { CheckIcon, XCircleIcon } from "lucide-react";
import { Button } from "@/components/Base/Button";

export type DatatableEmployee = Employee & {
  authAccountStatus: "inactive" | "active" | "doesNotExist";
};

interface ColumnProps {
  actions: (employee: Employee) => ReactNode[];
  loading?: boolean;
  onVisibilityChange: (id: string, visible: boolean) => void;
  onActivateAccount: (id: string) => void;
  onDeactivateAccount: (id: string) => void;
  onRoleChange: (id: string, role: EmployeeRole) => void;
}

const createActionsColumn = ({
  actions,
}: ColumnProps): ColumnDef<DatatableEmployee> => ({
  accessorKey: "actions",
  header: "",
  cell: ({ row }) => {
    const item = row.original;
    return (
      <div className="flex gap-2">
        {actions(item).map((action, index) => (
          <Fragment key={`action-${item.id}-${index}`}>{action}</Fragment>
        ))}
      </div>
    );
  },
});

const employeeColumns = (props?: ColumnProps) => {
  const col: ColumnDef<DatatableEmployee>[] = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "firstName",
      header: ({ column }) => <HeaderSortable column={column} title={"Name"} />,
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            <div
              className="text-base"
              id="employee_name"
            >{`${item.firstName} ${item.lastName}`}</div>
            <div className="text-sm" id="employee_email">
              {item.email}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "hideInShopHero",
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Visibility"} />
      ),
      sortingFn: (rowA) => {
        if (rowA.original.hideInShopHero === true) {
          return -1;
        }
        return 1;
      },
      cell: ({ row }) => {
        const item = row.original;
        return (
          <Select
            onValueChange={(value) =>
              props?.onVisibilityChange(item.id, value === "hidden")
            }
            value={item.hideInShopHero ? "hidden" : "visible"}
          >
            <SelectTrigger
              className={"w-48 text-primary "}
              id="employee_visibility"
            >
              <SelectValue placeholder="Visibility" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="hidden">Hidden in Shop Hero</SelectItem>
              <SelectItem value="visible">Visible in Shop Hero</SelectItem>
            </SelectContent>
          </Select>
        );
      },
    },
    {
      accessorKey: "existsInShopHero",
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Shop Hero Account"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div
            className="flex flex-row gap-2 items-center"
            id="employee_account"
          >
            {item.authAccountStatus === "active" ? (
              <>
                <CheckIcon />
                Active
                <div className={"flex-grow"} />
                <Button
                  id="employee_account_button"
                  loading={props?.loading}
                  variant="destructive"
                  onClick={() => {
                    props?.onDeactivateAccount(item.id);
                  }}
                  className="w-32"
                >
                  Deactivate
                </Button>
              </>
            ) : (
              <>
                <XCircleIcon />{" "}
                {item.authAccountStatus === "inactive"
                  ? "Disabled"
                  : "Does Not Exist"}
                <div className={"flex-grow"} />
                <Button
                  id="employee_account_button"
                  loading={props?.loading}
                  variant="default"
                  onClick={() => {
                    props?.onActivateAccount(item.id);
                  }}
                  className="w-32"
                >
                  {item.authAccountStatus === "inactive"
                    ? "Re-Activate"
                    : "Activate"}
                </Button>
              </>
            )}{" "}
          </div>
        );
      },
    },
    {
      accessorKey: "shopHeroRole",
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Employee Role"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        if (item.authAccountStatus !== "active") {
          return null;
        }
        return (
          <Select
            onValueChange={(value: EmployeeRole) =>
              props?.onRoleChange(item.id, value)
            }
            value={item.shopHeroRole ?? "admin"} // Default to admin because behind the scenes we do.
          >
            <SelectTrigger
              className={"w-48 text-primary "}
              loading={props?.loading}
              id="employee_role"
            >
              <SelectValue placeholder="Role" />
            </SelectTrigger>
            <SelectContent>
              {EmployeeRole.map((item) => (
                <SelectItem value={item} key={item}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      },
    },
  ];

  if (props) {
    col.push(createActionsColumn(props));
  }
  return col;
};

export { employeeColumns };
