import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useStore } from "@/stores/useStore";
import { LayoutCreator } from "@/components/Layout/LayoutCreator";
import { useRoles } from "@/hooks/useRoles";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";

const LayoutBuilderPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopData = useStore((s) => s.shop);

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  return (
    <>
      <div className="pt-5 px-2">
        <h1 style={{ fontSize: 24 }}>Layout Builder</h1>
        {shopData ? <LayoutCreator /> : <div>Loading...</div>}
      </div>
    </>
  );
};

export default LayoutBuilderPage;
