import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";

export const addContainerToContainers = async (payload) => {
  const shopRef = doc(db, "shops", payload.shopId);
  const containerObj = { id: payload.newContainerName, tags: [] };

  const returnValue = await updateDoc(shopRef, {
    containers: arrayUnion(containerObj),
  })
    .then(() => {
      return "success";
    })
    .catch((err) => {
      console.error("addLayoutToLayouts error", err);
      return err;
    });
  return returnValue;
};
