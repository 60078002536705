import { Button } from "@/components/Base/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Base/Form";
import { Input } from "@/components/Base/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { useStore } from "@/stores/useStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { ZodObject, ZodRawShape, z } from "zod";
import { CARD_FIELDS, CardFieldKeys, PatchCardDto } from "@/types/v2";
import { getServiceAdvisorColor } from "@/lib/employees";

const shape: ZodRawShape = {};
CARD_FIELDS.forEach((field) => {
  shape[field.key] = z.string().optional();
});
const formSchema: ZodObject<typeof shape> = z.object({
  cardNumber: z.string().min(1, {
    message: "Repair Order Number must exist",
  }),
  source: z.string().readonly(),
  schemaVersion: z.number().int(),
  serviceWriterId: z.string().optional(),
  technicianId: z.string().optional(),
  ...shape,
});

// eslint-disable-next-line no-unused-vars
const defaultValues: { [key in CardFieldKeys]?: string } = {};
CARD_FIELDS.forEach((field) => {
  defaultValues[field.key] = "";
});

export interface CardFormProps<T> {
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  cardData?: PatchCardDto;
  onSubmit: (values: T) => Promise<void>;
}

export const CardForm = <T,>({
  cancelButton,
  submitButton,
  cardData,
  onSubmit,
}: CardFormProps<T>) => {
  const shop = useStore((s) => s.shop);
  const serviceAdvisors = useStore((s) => s.employees);
  const technicians = useStore((s) => s.employees);

  const formDefaultValues = {
    source: "manual",
    schemaVersion: 2,
    cardNumber: "",
    serviceWriterId: "none",
    technicianId: "none",
    ...defaultValues,
    ...cardData,
  };

  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });
  // 2. Define a submit handler.

  if (!shop) return null;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => await onSubmit(data as T))}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="cardNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repair Order Number</FormLabel>
              <FormControl>
                <Input
                  disabled={form.formState.isSubmitting}
                  placeholder="0001"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    CARD_FIELDS.forEach((cardField) => {
                      if (
                        shop?.cardConfig.cardContents[cardField.key] ==
                        "RO Number"
                      ) {
                        console.log(`Setting ${cardField.key}`);
                        form.setValue(cardField.key, e.target.value, {
                          shouldValidate: true,
                        });
                      }
                    });
                  }}
                  className="text-primary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {CARD_FIELDS.map((cardField) => (
          <FormField
            key={cardField.key}
            control={form.control}
            name={cardField.key}
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row justify-between">
                  <FormLabel>{`${shop?.cardConfig.cardContents[cardField.key]}`}</FormLabel>
                </div>
                <FormControl>
                  <Input
                    placeholder=""
                    {...field}
                    className="text-primary"
                    disabled={
                      shop?.cardConfig.cardContents[cardField.key] ==
                        "RO Number" || form.formState.isSubmitting
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}

        {/* Add Service Advisor Select */}
        <div className="flex flex-row justify-between">
          <FormField
            control={form.control}
            name="serviceWriterId" // Make sure this name matches the field in your form
            render={({ field }) => (
              <FormItem>
                <FormLabel>Service Advisor</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value); // Update form field value
                    }}
                    value={field.value} // Ensure the correct value is displayed
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Service Advisor" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="none">No Service Writer</SelectItem>
                      {serviceAdvisors
                        ?.filter((sa) => !sa.hideInShopHero)
                        .map((serviceAdvisor) => (
                          <SelectItem
                            key={serviceAdvisor.id}
                            value={`${serviceAdvisor.id}`}
                          >
                            {/* Circle color next to the name */}
                            <span
                              className="inline-block w-2.5 h-2.5 rounded-full mr-2"
                              style={{
                                backgroundColor: getServiceAdvisorColor(
                                  serviceAdvisor.id,
                                  serviceAdvisors || []
                                ),
                              }}
                            ></span>
                            {serviceAdvisor.firstName} {serviceAdvisor.lastName}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Add Technician Select */}
          <FormField
            control={form.control}
            name="technicianId" // Make sure this name matches the field in your form
            render={({ field }) => (
              <FormItem>
                <FormLabel>Technician</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value); // Update form field value
                    }}
                    value={field.value} // Ensure the correct value is displayed
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Technician" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="none">No Technician</SelectItem>
                      {technicians
                        ?.filter((tech) => !tech.hideInShopHero)
                        .map((technician) => (
                          <SelectItem
                            key={technician.id}
                            value={`${technician.id}`}
                          >
                            {technician.firstName} {technician.lastName}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex flex-row justify-end gap-4">
          {cancelButton ? cancelButton : null}
          {submitButton ? (
            submitButton
          ) : (
            <Button
              variant="primary-alt"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};
