import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { config } from "@/config";
import { app } from "./app";

const functions = getFunctions(app);

// connect / export
if (config.emulator) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
export { functions };
