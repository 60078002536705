import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";

const IsAuthenticatedRedirect = () => {
  useIsAuthenticated();

  return (
    <div>
      <h1>You are authenticated</h1>
    </div>
  );
};

export default IsAuthenticatedRedirect;
