import { firestore } from "../services/firestore";

export const firestoreCard = {
  subscribeAll: async (shopId, callback, statuses) => {
    // console.log("IN subscribeAll", { shopId, statuses });
    return firestore.subscribeCollectionWQuery(
      `shops/${shopId}/cards`,
      callback,
      "repairOrderStatus",
      "in",
      [...statuses, "manuallyCreated"]
    );
  },
};
