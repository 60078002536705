import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useCallback, useEffect, useState } from "react";
import { timers } from "../../data/Context/shop";
import { Container } from "./components/Containers/Container";
import { useFeature } from "flagged";
import { useStore } from "@/stores/useStore";
import { Layout } from "@/types/v2";
import { Card } from "@/components/Dashboard/components/Card/Card";
import { Card as CardType, Container as ContainerType } from "@/types/v2";
import { httpsCallables } from "@/models/httpsCallables";
import { useDebounceCallback } from "@/hooks/useDebounceCallback";

export const DnDContainer = ({
  selectedLayout,
  serviceAdvisorId,
  technicianId,
  ticker,
}: {
  selectedLayout: Layout[];
  serviceAdvisorId?: string;
  technicianId?: string;
  ticker: number;
}) => {
  const [items, setItems] = useState<{ [x: string]: string[] }>([]);
  const [timerStarted, setTimerStarted] = useState(false);

  // CONTAINER MANAGEMENT
  const [activeId, setActiveId] = useState<string>();
  const [activeContainerId, setActiveContainerId] = useState<string>();
  const [activeIdContainerIndex, setActiveIdContainerIndex] =
    useState<number>();

  const moveCard = httpsCallables.moveCard;
  // Features
  const isServiceAdvisorFilterEnabled = useFeature(
    "isServiceAdvisorFilterEnabled"
  );
  const isTechnicianFilterEnabled = useFeature("isTechnicianFilterEnabled");
  const isManualCardOnlyEnabled = useFeature("isManualCardOnlyEnabled");
  const isManualCardsEnabled = useFeature("isManualCardsEnabled");

  // SUBSCRIBE TO FIRESTORE SHOP QUERY TO RETRIEVE CONTAINERS AND LAYOUTS INFO
  // Did this on App.jsx
  const timersData = timers.use();
  const { shopId, cards, containers: containersData } = useStore();

  const sensors = useSensors(
    // useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    })
  );

  const updateItemsFromCards = useCallback(
    (cards: CardType[], containers: ContainerType[]) => {
      const newContainersObject: { [x: string]: string[] } = {};
      containers.forEach((container) => {
        newContainersObject[container.id] = container.tags.filter((t) => {
          const card = cards.find((c) => c.id === t);

          if (!card) {
            return false;
          }
          if (
            isServiceAdvisorFilterEnabled &&
            isTechnicianFilterEnabled &&
            (serviceAdvisorId !== "all" || technicianId !== "all")
          ) {
            if (serviceAdvisorId !== "all" && technicianId !== "all") {
              return (
                card.serviceWriterId === serviceAdvisorId &&
                card.technicianId === technicianId
              );
            }
            if (serviceAdvisorId !== "all") {
              return card.serviceWriterId === serviceAdvisorId;
            }
            if (technicianId !== "all") {
              return card.technicianId === technicianId;
            }
          }
          if (isServiceAdvisorFilterEnabled && serviceAdvisorId !== "all") {
            return card.serviceWriterId === serviceAdvisorId;
          }
          if (isTechnicianFilterEnabled && technicianId !== "all") {
            return card.technicianId === technicianId;
          }
          if (isManualCardOnlyEnabled) {
            return card.source === "manual";
          }
          if (!isManualCardsEnabled) {
            return card.source !== "manual";
          }
          return true;
        });
      });
      setItems(newContainersObject);
    },
    [
      serviceAdvisorId,
      technicianId,
      isServiceAdvisorFilterEnabled,
      isTechnicianFilterEnabled,
      isManualCardOnlyEnabled,
      isManualCardsEnabled,
    ]
  );

  const debouncedUpdateItems = useDebounceCallback(updateItemsFromCards, 500);
  useEffect(() => {
    if (containersData) {
      debouncedUpdateItems(cards, containersData);
    }
  }, [containersData, cards, debouncedUpdateItems]);

  // shutdown timer
  useEffect(() => {
    if (!timersData || !Object.keys(timersData).length) {
      if (timerStarted) {
        // window.timer.port.start()
        // window.timer.port.postMessage('stop');
        window.timer.postMessage("stop");
        //localStorage.setItem('timer', 'false');
        setTimerStarted(false);
      }
    }
  }, [timersData, timerStarted]);

  // initialize timer
  useEffect(() => {
    // const condition = timers && Object.keys(timers).length && !JSON.parse(localStorage.getItem('timer'))
    const condition =
      timersData && Object.keys(timersData).length && timerStarted === false;
    if (condition) {
      console.log(">>> Timer started");
      //localStorage.setItem('timer', 'true');
      // window.timer.port.start()
      // window.timer.port.postMessage('start');
      window.timer.postMessage("start");
      setTimerStarted(true);
    }
  }, [timersData, timerStarted]);

  // Repair Order

  function findContainer(id: string) {
    if (id in items) {
      return id;
    }
    // console.log(items, Object.keys(items))
    return Object.keys(items).find((key) =>
      items[key as keyof typeof items].includes(id)
    );
  }

  function handleDragStart(event: DragStartEvent) {
    // console.log("START @handleDragStart")
    const id = event.active.id;

    setActiveId(id ? `${id}` : undefined);
    if (id) {
      const ac = findContainer(`${id}`);
      setActiveContainerId(ac);
      setActiveIdContainerIndex(
        items[ac as keyof typeof items].indexOf(`${id}`)
      );
    } else {
      setActiveContainerId(undefined);
      setActiveIdContainerIndex(undefined);
    }
  }

  function handleDragOver(event: DragOverEvent) {
    // console.log("OVER @handleDragOver")
    const { active, over, draggingRect } = event;

    console.log(draggingRect);
    const { id } = active;
    if (!over) {
      return;
    }
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect?.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event: DragEndEvent) {
    // console.log("END @handleDragEnd")
    // UPDATE LOCAL STATE
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    // console.log("DragEnd: active stored:", activeContainerId, activeIdContainerIndex, "; active now:",  activeContainer, activeIndex,  "; go to:",  overContainer, overIndex);
    //from activeContainerId, activeIdContainerIndex
    //to overContainer, overIndex

    // console.log({activeId})
    // console.log({event})

    //////////////////////////////////////////////////////////////////////////////////
    if (!(typeof shopId === "string")) {
      console.error("ERROR", `shopId is ${shopId}`);
      return;
    }

    if (!(typeof activeId === "string")) {
      console.error("ERROR", `activeId is ${activeId}`);
      return;
    }

    if (!(typeof activeIdContainerIndex === "number")) {
      console.error(
        "ERROR",
        `activeIdContainerIndex is ${activeIdContainerIndex}`
      );
      return;
    }

    if (!(typeof activeContainerId === "string")) {
      console.error("ERROR", `activeContainerId is ${activeContainerId}`);
      return;
    }

    if (!(typeof overIndex === "number")) {
      console.error("ERROR", `overIndex is ${overIndex}`);
      return;
    }

    if (!(typeof overContainer === "string")) {
      console.error("ERROR", `overContainer is ${overContainer}`);
      return;
    }
    //////////////////////////////////////////////////////////////////////////////////

    setItems((items) => ({
      ...items,
      [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    }));

    // UPDATE FIRESTORE CONTAINERS
    // const payload = {};
    // payload.shopId = shopId;
    // payload.containersObj = {
    //   ...items,
    //   [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    // };

    const activeIdToSend = activeId;

    setActiveId(null);
    setActiveContainerId(null);
    setActiveIdContainerIndex(null);

    moveCard({
      shopId,
      cardId: activeIdToSend,
      toContainerId: overContainer,
      toIndex: overIndex,
    });
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      {Object.keys(items).map((key, index) => {
        const gridParams = selectedLayout.find(
          (paramObj) => paramObj.i === key
        );
        const container = containersData.find((c) => c.id === key);

        if (gridParams && container) {
          const gridArea = `${gridParams.y + 1} / ${gridParams.x + 1} / ${
            gridParams.y + 1 + gridParams.h
          } /${gridParams.x + 1 + gridParams.w}`;
          return (
            <div
              key={`${key}-${index}`}
              id={key}
              style={{
                width: "100%", // Equivalent to w-full
                gridArea: `${gridParams.y + 1} / ${gridParams.x + 1} / ${
                  gridParams.y + 1 + gridParams.h
                } / ${gridParams.x + 1 + gridParams.w}`,
                backgroundColor: "#3C4043",
                borderRadius: "10px",
                paddingLeft: "2px",
                paddingRight: "2px",
                overflow: "auto",
                color: "white",
                minHeight: "180px",
              }}
            >
              <Container
                {...container}
                tags={items[container.id]}
                direction={gridParams.w >= gridParams.h * 8 ? "row" : "column"}
                gridArea={gridArea}
                ticker={ticker}
                displayName={container?.displayName ?? key}
                draggingId={activeId}
              />
            </div>
          );
        } else return null;
      })}
      <DragOverlay>
        {activeId ? (
          <Card cardId={activeId} ticker={ticker} isDragging />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
