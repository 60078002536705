const ErrorPage = () => {
  return (
    <div>
      <h1>Uh Oh!</h1>
      <p>
        Something went wrong. We are sorry this happened. Please reach out to
        support.
      </p>
    </div>
  );
};

export default ErrorPage;
