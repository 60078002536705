import { Card } from "@/types/v1";
import { ClockIcon } from "lucide-react";

interface JobHourDisplayProps extends Pick<Card, "totalLaborHours"> {}
export const JobHourDisplay = ({ totalLaborHours }: JobHourDisplayProps) => {
  return (
    <div className="flex flex-row items-center justify-center">
      <ClockIcon size={14} />
      <p className="text-sm">{`${
        Math.round((totalLaborHours?.loggedLabor ?? 0) * 10) / 10
      }/${Math.round((totalLaborHours?.estimatedLabor ?? 0) * 10) / 10}`}</p>
    </div>
  );
};
