import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useStore } from "@/stores/useStore";
import { EmployeesTable } from "@/components/Employees/Employees.table";
import { EmployeeDialog } from "@/components/Employees/Employee.dialog";
import { Button } from "@/components/Base/Button";
import { Spinner } from "@/components/Common/Spinner";
import { useState } from "react";
import { CircleHelpIcon, PlusIcon } from "lucide-react";
import { useRoles } from "@/hooks/useRoles";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  LIFECYCLE,
  STATUS,
} from "react-joyride";
import { EMPLOYEE_TABLE_STEPS } from "@/lib/tours/employeePage";

const LOCALE = {
  back: "Back",
  close: "Close",
  last: "Close",
  next: "Next",
  open: "Open the dialog",
  skip: "Skip",
};

const EmployeesPage = () => {
  const { isSuperAdmin, isAdmin, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopId = useStore((s) => s.shopId);
  const [createEmployeeDialogOpen, setCreateEmployeeDialogOpen] =
    useState(false);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const handleStartTour = () => {
    setStepIndex(0);
    setRun(true);
  };

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { action, index, lifecycle, type, status } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setStepIndex(0);
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      //
      if (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) {
        setRun(false);
        setStepIndex(0);
      } else {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(nextStepIndex);
      }
    }
  };

  if (!shopId) {
    return (
      <div>
        <Spinner size={"lg"} />
      </div>
    );
  }

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin)) return <Forbidden />;

  return (
    <div className="p-4">
      <Joyride
        steps={EMPLOYEE_TABLE_STEPS}
        stepIndex={stepIndex}
        locale={LOCALE}
        spotlightPadding={0}
        continuous
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            overlayColor: "rgba(0, 0, 0, 0.4)",
            primaryColor: "#1f2023",
            textColor: "#000",
            zIndex: 1000,
          },
        }}
      />
      <div className="flex flex-row gap-2">
        <EmployeeDialog
          shopId={shopId}
          open={createEmployeeDialogOpen}
          setOpen={setCreateEmployeeDialogOpen}
        />
        <Button
          variant={"secondary"}
          onClick={() => setCreateEmployeeDialogOpen(true)}
          id="create_employee"
        >
          <PlusIcon size={18} className="mr-2" />
          Create Team Member
        </Button>
        <div className="flex-grow" />
        <Button variant="secondary" size="icon" onClick={handleStartTour}>
          <CircleHelpIcon />
        </Button>
      </div>
      <EmployeesTable />
    </div>
  );
};

export default EmployeesPage;
