import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { app } from "./app";
import { config } from "@/config";

const storage = getStorage(app);

if (config.emulator) {
  connectStorageEmulator(storage, "localhost", 9199);
}

const storageActions = {
  getDownloadURL: (path: string) => {
    const storageRef = ref(storage, path);
    return getDownloadURL(storageRef);
  },

  upload: async (file: File | Blob, path: string) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file).catch((err) =>
      console.error("An error occurred while uploading the file:", err)
    );
    return getDownloadURL(storageRef);
  },
};

export { storage, storageActions };
