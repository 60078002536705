import { firestore } from "@/services/firestore";
import { CreateEmployeeDto, Employee, EmployeeRole } from "@/types/v2";
import { Employee as EmployeeV1 } from "@/types/v1";
import { createConverter } from "@/models/common";
import { shop } from "@/models/shop";
import { user } from "@/models/user";
import { generic } from "@/models/generic";

export const employee = {
  get: (shopId: string, employeeId: string) => {
    return firestore.getDocument<Employee>(
      `shops/${shopId}/employees/${employeeId}`,
      createConverter<EmployeeV1, Employee>(employee)
    );
  },
  create: async (shopId: string, employeeData: CreateEmployeeDto) => {
    return generic.create<Employee>(employeeData, `shops/${shopId}/employees/`);
  },
  delete: async (shopId: string, employeeId: string) => {
    return generic.delete(employeeId, `shops/${shopId}/employees`, true);
  },
  isV1: (employee: EmployeeV1 | Employee): employee is EmployeeV1 => {
    return (employee as Employee).schemaVersion === undefined;
  },
  transformV1ToV2: (employee: EmployeeV1): Employee => {
    return {
      ...employee,
      id: `${employee.id}`,
      sourceLastUpdated: new Date(0),
      schemaVersion: 2,
    };
  },
  subscribeAll: (
    id: string,
    callback: (doc: Employee[] | undefined) => void
  ) => {
    return firestore.subscribeCollection<Employee>(
      `shops/${id}/employees`,
      callback,
      createConverter<EmployeeV1, Employee>(employee)
    );
  },
  update: async (
    shopId: string,
    employeeId: string,
    employeeData: Partial<Employee>
  ) => {
    return firestore.updateDocument<Partial<Employee>>(
      `shops/${shopId}/employees/${employeeId}`,
      { ...employeeData }
    );
  },

  toggleVisibility: async (shopId: string, id: string, hidden: boolean) => {
    return employee.update(shopId, id, { hideInShopHero: hidden });
  },
  updateEmployeeRole: async (
    shopId: string,
    employeeId: string,
    role?: EmployeeRole
  ) => {
    const authUser = await removeExistingRoles(shopId, employeeId);

    console.log(authUser);
    if (!authUser) {
      return;
    }

    // If employee role is undefined, then we are good. Nothing else to do, except update the employee

    if (role) {
      await user.updateUserRole({
        userId: authUser.data.uid,
        userRole: role,
        userRoleEnabled: true,
        userShopId: shopId,
      });
    }
    return employee.update(shopId, employeeId, {
      shopHeroRole: role ?? null,
      shopHeroAuthUid: authUser.data.uid,
    });
  },
};

const removeExistingRoles = async (shopId: string, employeeId: string) => {
  const employeeData = await employee.get(shopId, employeeId);
  const shopData = await shop.get(shopId);
  if (!shopData || !employeeData) return;
  const authUser = await user.getId(employeeData.email);
  if (!authUser.data) return;
  const currentRoles: EmployeeRole[] = [];
  if (shopData.adminUserIds?.includes(authUser.data.uid)) {
    currentRoles.push("admin");
  }
  if (shopData.technicianUserIds?.includes(authUser.data.uid)) {
    currentRoles.push("technician");
  }
  if (shopData.officeUserIds?.includes(authUser.data.uid)) {
    currentRoles.push("office");
  }
  if (shopData.viewOnlyUserIds?.includes(authUser.data.uid)) {
    currentRoles.push("viewOnly");
  }
  console.log({ shopData, currentRoles });
  for (const role of currentRoles) {
    console.log(`Removing existing user role ${role}`);
    await user.updateUserRole({
      userId: authUser.data.uid,
      userRole: role,
      userRoleEnabled: false,
      userShopId: shopId,
    });
  }
  return authUser;
};
