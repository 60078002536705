import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const updateContainers = async (payload) => {
  let payloadArray = payload.containers;
  if (Array.isArray(payload.containers) === false) {
    payloadArray = [];

    Object.keys(payload.containersObj).forEach((key) => {
      payloadArray.push({ id: key, tags: payload.containersObj[key] });
    });
  }

  const docRef = doc(db, "shops", payload.shopId);

  await updateDoc(docRef, {
    containers: payloadArray,
  });
};
