import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { capitalize } from "@/lib/utils";
import { useStore } from "@/stores/useStore";
import { Card } from "@/types/v2";
import { CopyIcon } from "lucide-react";
import { useState } from "react";

interface DebugInfoProps {
  cardData: Card;
  setOpen: (open: boolean) => void;
  open: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const CardField = ({ name, value }: { name: string; value: any }) => {
  const [copiedText, copy] = useCopyToClipboard();
  const [copyPopoverOpen, setCopyPopoverOpen] = useState(false);
  const [copyResults, setCopyResults] = useState({ success: false, error: "" });

  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        setCopyResults({ success: true, error: "" });
      })
      .catch((error) => {
        setCopyResults({ success: false, error: error });
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setCopyPopoverOpen(false);
        }, 2000);
      });
  };
  return (
    <div className="flex flex-row justify-start items-center gap-4">
      <div className="font-bold">{name}:</div>
      <pre>{value}</pre>
      <div className="flex-grow" />
      <Popover open={copyPopoverOpen} onOpenChange={setCopyPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant="ghost" size="icon" onClick={() => handleCopy(value)}>
            <CopyIcon />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          {copyResults.success
            ? `${copiedText} copied!`
            : `Error copying: ${copyResults.error}`}
        </PopoverContent>
      </Popover>
    </div>
  );
};
export const DebugInfo = ({ cardData, open, setOpen }: DebugInfoProps) => {
  const shopId = useStore((s) => s.shopId);
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Card Debug Info</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <CardField name="Shop ID" value={shopId} />
          <CardField name="Card ID" value={cardData.id} />
          <CardField name="Repair Order Number" value={cardData.cardNumber} />
          <CardField
            name="Created Via"
            value={capitalize(cardData.source ?? "Unknown")}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
