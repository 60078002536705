import { entity } from "simpler-state";
import { pushSubscriber, resetSubscribers } from "./subscribers";
import { firestoreShop } from "../firestoreShop";
import { firestoreEmployee } from "../firestoreEmployee";
import { firestoreTimer } from "../firestoreTimer";
import { firestoreCard } from "../firestoreCard";
import { subscribeCards } from "../Cards/subscribeCards";
import { setUserFeatureFlags } from "./featureFlags";
import { KEYS, localStorageManager } from "../../services/localStorage";
import { store } from "../../stores/useStore";
import { sentry } from "../../services/sentry";

export const currentShopId = entity(null);
export const containers = entity([]);
export const layouts = entity([]);
export const timers = entity([]);
export const defaultTimer = entity(0);
export const cardLabelWrap = entity(null);
export const cardContents = entity({});
export const repairOrders = entity([]);
export const employees = entity([]);
export const lastSyncedTimestamp = entity(0);
export const statuses = entity([]);
export const cardsEntity = entity([]);
export const refreshRate = entity(300);
export const shopEntity = entity({ logoExists: false, logoUrl: "" });

export const setShopKey = (key, value) => {
  shopEntity.set({ ...shopEntity.get(), [key]: value });
};
export const setCurrentShopId = async (shopObj) => {
  if (shopObj === "unverified") {
    currentShopId.set("unverified");
    return;
  }
  localStorageManager.setItem(KEYS.SHOP_ID, shopObj);
  if (shopObj !== null) {
    subscribeShop(shopObj);
  } else {
    currentShopId.set(null);
  }
};

export const setContainers = (newContainers) => {
  containers.set(newContainers);
};

export const setEmployees = (newEmployees) => {
  employees.set(newEmployees);
};

export const setLayouts = (newLayouts) => {
  layouts.set(newLayouts);
};

export const setTimers = (newTimers) => {
  timers.set(newTimers);
};

export const setDefaultTimer = (newTimer) => {
  defaultTimer.set(newTimer);
};

export const setCardContents = (newContents) => {
  cardContents.set(newContents);
};

export const setCardLabelWrap = (newLabelWrap) => {
  cardLabelWrap.set(newLabelWrap);
};

export const setRepairOrders = (newRepairOrders) => {
  repairOrders.set(newRepairOrders);
};

export const setLastSyncedTimestamp = (newLastSyncedTimestamp) => {
  lastSyncedTimestamp.set(newLastSyncedTimestamp);
};

export const setStatusesToFetch = async (payload) => {
  statuses.set(payload);
};

export const setCardsEntity = (cards) => {
  cardsEntity.set(cards);
};

export const setShopRefreshRate = (refreshTimeInSeconds) => {
  refreshRate.set(refreshTimeInSeconds);
};

export const subscribeShop = async (shopId) => {
  if (shopId === "unverified") {
    currentShopId.set("unverified");
    resetSubscribers();

    // TODO: Remove all subscribers
    return;
  }
  if (shopId !== null) {
    if (shopId !== currentShopId.get()) {
      resetSubscribers();
      // console.log("Setting feature flags for ", shopId);
      setUserFeatureFlags(shopId);
    }
    currentShopId.set(shopId);
  } else {
    // TODO: Remove all subscribers
    currentShopId.set(null);
    resetSubscribers();
    return;
  }

  // console.log("Subscribing to shop");
  // Subscribe to shop & set entities accordingly.
  const subscriber = firestoreShop.subscribeShop(shopId, (shop) => {
    store.setState({
      shop: shop,
      shopId: shopId,
      lastSyncedTime: shop.lastSyncedTimestamp,
      layouts: shop.layouts,
      containers: shop.containers,
      defaultTimer: shop.defaultTimer,
      cardLabelWrap: shop.cardLabelWrap,
      cardContents: shop.cardContents,
      statuses: shop.statuses,
      lastSyncedTimestamp: shop.lastSyncedTimestamp || 0,
      refreshRate: shop.refreshTimeInSeconds ?? 300,
    });
    store.getState().getLogoUrl();
    store.getState().subscribeSyncDriver();
    store.getState().subscribeCards();

    const user = store.getState().user;
    sentry.setUser({ id: user?.id, email: user?.email, shopId: shopId });
    // Log shop
    containers.set(shop.containers);
    layouts.set(shop.layouts);
    defaultTimer.set(shop.defaultTimer);
    cardContents.set(shop.cardContents);
    cardLabelWrap.set(shop.cardLabelWrap);
    if (
      shop.statuses.every((status) => statuses.get().includes(status)) === false
    ) {
      subscribeCards(shopId, shop.statuses);
    }
    statuses.set(shop.statuses);
    lastSyncedTimestamp.set(shop.lastSyncedTimestamp || 0);
    refreshRate.set(shop.refreshTimeInSeconds ?? 300);
    store.getState().setFeatureFlags();
  });
  pushSubscriber(subscriber, "currentShop");

  const employeeSubscriber = firestoreEmployee.subscribeAll(
    shopId,
    (employeeDocs) => {
      employees.set(employeeDocs);
      store.setState({
        employees: employeeDocs,
      });
    }
  );
  pushSubscriber(employeeSubscriber, "employees");

  const timerSubscriber = firestoreTimer.subscribeAll(shopId, (timerDocs) => {
    const reducedTimers = timerDocs.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {}
    );
    timers.set(reducedTimers);
    store.setState({
      timers: reducedTimers,
    });
  });

  pushSubscriber(timerSubscriber, "timers");
};
