import { connectAuthEmulator, getAuth } from "firebase/auth";
import { app } from "./app";
import { config } from "@/config";
import { AuthRole } from "@/types/v1";
import { AuthCustomClaims } from "@/types/v2";
import { httpsCallables } from "@/models/httpsCallables";

const auth = getAuth(app);

if (config.emulator) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

const authActions = {
  getUserClaims: async () => {
    const user = auth.currentUser;
    if (user) {
      let claims = await user.getIdTokenResult(true);
      // Migrate for v3.1.0
      if (
        !(claims.claims as AuthCustomClaims).user ||
        !(claims.claims as AuthCustomClaims).superAdmin
      ) {
        // Migrate User claims
        await httpsCallables.updateUserClaims({ userId: user.uid });
        await user.getIdToken(true);
        claims = await user.getIdTokenResult();
      }
      return claims.claims as AuthCustomClaims;
    }
    return;
  },
  getUserClaimRole: async () => {
    const claims = await authActions.getUserClaims();
    if (claims?.role) {
      return claims.role as AuthRole;
    }
    if (claims) {
      return "user" as AuthRole;
    }
    return;
  },
};

export { auth, authActions };
