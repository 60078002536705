import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../Base/Tooltip";
import { TagIcon, UserIcon } from "lucide-react";
import { useStore } from "@/stores/useStore";
import { formatLabels, formatTechnicianNames } from "@/lib/cards";
import { Container as ContainerType } from "@/types/v2";
import { Card } from "@/components/Dashboard/components/Card/Card";
import { useRoles } from "@/hooks/useRoles";

interface ContainerProps extends ContainerType {
  direction: "row" | "column";
  gridArea: string;
  ticker: number;
  draggingId?: string;
}
export const Container = (props: ContainerProps) => {
  const { id, tags: items } = props;
  const { isViewOnly } = useRoles();
  const employees = useStore((s) => s.employees);

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div className="relative">
      <div className="sticky top-0 flex bg-containerGray z-10 flex-row w-full justify-between p-1">
        <p>{props.displayName}</p>
        <div>
          {props.linkedSourceLabel && (
            <Tooltip>
              <TooltipTrigger>
                <TagIcon className="w-4 h-4" />
              </TooltipTrigger>
              <TooltipContent>
                {`Linked to ${formatLabels(props.linkedSourceLabel)}`}
              </TooltipContent>
            </Tooltip>
          )}

          {props.linkedTechnicianId && employees && (
            <Tooltip>
              <TooltipTrigger>
                <UserIcon className="w-4 h-4" />
              </TooltipTrigger>
              <TooltipContent>
                {`Linked to ${formatTechnicianNames(props.linkedTechnicianId, employees)}`}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>

      <SortableContext
        disabled={isViewOnly}
        id={id}
        items={items}
        strategy={
          props.direction === "row"
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
      >
        <div ref={setNodeRef} className="flex overflow-auto flex-wrap flex-row">
          {props.tags.map((tag) => {
            return (
              <Card
                key={tag}
                cardId={tag}
                ticker={props.ticker}
                tentative={tag === props.draggingId}
              />
            );
          })}
        </div>
      </SortableContext>
    </div>
  );
};
