import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Base/Button";

export const Forbidden = () => {
  const navigate = useNavigate();

  const onPressHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-grow justify-center items-center gap-4 flex-col">
      <h1 className="text-4xl font-bold">403 - Forbidden</h1>
      <p>You do not have permission to access this page.</p>
      <Button onClick={onPressHome}>Go Home</Button>
    </div>
  );
};
