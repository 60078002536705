import { useEffect, useState } from "react";
import GridLayout from "./GridLayout";
import { layout as layoutModel } from "@/models/layout";
import { useStore } from "@/stores/useStore";

import { addLayoutToLayouts } from "../../data/Layouts/addLayoutToLayouts";
import { addContainerToContainers } from "../../data/Containers/addContainerToContainers";
import { useQueryClient } from "react-query";
import { updateLayoutsArray } from "../../data/Layouts/updateLayoutsArray";
import clone from "just-clone";
import { setStatusesToFetch as setStatusesToCollect } from "../../data/Context/shop";
import { EditContainerName } from "../Dialogs/EditContainerName";
import { updateContainers } from "../../data/Containers/updateContainers";
import { ContainerHasItemsWarning } from "../Dialogs/ContainerHasItemsWarning";
import { overwriteLayoutsArray } from "../../data/Layouts/overwriteLayoutsArray";

import addIcon from "../../assets/add.png";
import removeIcon from "../../assets/remove.png";
import editIcon from "../../assets/edit.png";

export const LayoutCreator = () => {
  const [layout, setLayout] = useState(layoutModel.generateBaseLayout());
  const shopId = useStore((s) => s.shopId);

  const [layouts, setLayouts] = useState([]);
  const [containerIds, setContainerIds] = useState([]);
  const [newContainerName, setNewContainerName] = useState("");
  const [newLayoutName, setNewLayoutName] = useState("");
  const [originalContainerName, setOriginalContainerName] = useState("");
  const [editContainerName, setEditContainerName] = useState("");
  const [editContainerOpen, setEditContainerOpen] = useState(false);
  const [containerHasItemsWarningOpen, setContainerHasItemsWarningOpen] =
    useState(false);

  const queryClient = useQueryClient();

  // SUBSCRIBE TO FIRESTORE SHOP QUERY TO RETRIEVE CONTAINERS AND LAYOUTS INFO
  const shopData = useStore((s) => s.shop);

  useEffect(() => {
    if (shopData) {
      const containerIds = shopData.containers.map((container) => container.id);
      setContainerIds(containerIds);
      setLayouts(shopData?.layouts);
      if (shopData?.statusesToSync.length > 0) {
        const statusesAsStrings = shopData?.statusesToSync.map(
          (status) => status
        );
        setStatusesToCollect(statusesAsStrings);
      }
    }
  }, [shopData]);

  const createContainerFromExisting = (containerName) => {
    const layoutCopy = layout.slice();
    if (layoutCopy.find((layout) => layout.i === containerName) === undefined) {
      const newLayout = layoutCopy.concat({
        i: containerName,
        x: 0,
        y: 0,
        w: 16,
        h: 6,
      });
      setLayout(newLayout);
    } else {
      alert("Container already exists in this layout");
    }
  };

  const deleteExistingContainer = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const layoutsClone = clone(layouts);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );
    const containerIndex = containersClone.findIndex(
      (container) => container.id === containerName
    );
    if (containerName === "dispatch") {
      alert("Dispatch container cannot be deleted");
      return;
    }
    if (
      window.confirm(
        "THIS WILL REMOVE THE CONTAINER FROM ALL EXISTING LAYOUTS AND CANNOT BE UNDONE. Are you sure you want to delete this container from all layouts?"
      )
    ) {
      // CHECK IF CONTAINER IS EMPTY
      const containerHasItems = containerToEdit.tags.length > 0;

      // IF CONTAINER IS NOT EMPTY, PROMPT USER TO EMPTY CONTAINER FIRST
      if (containerHasItems) {
        setContainerHasItemsWarningOpen(true);
        return;
      }

      const removeContainerNameFromLayouts = (name) => (layout) => {
        return Object.entries(layout).map(([key, value]) => ({
          [key]: value.filter(({ i }) => i !== name),
        }));
      };

      //console.log(layoutsClone.map(removeContainerNameFromLayouts(containerName)))
      //return

      // IF CONTAINER IS EMPTY, DELETE CONTAINER FROM FIRESTORE
      containersClone.splice(containerIndex, 1);
      const payload = {};
      payload.shopId = shopId;
      payload.containers = containersClone;
      const updatedLayouts = layoutsClone
        .map(removeContainerNameFromLayouts(containerName))
        .reduce((acc, curr) => [...acc, ...curr], []);
      updateContainers(payload);
      updateLayoutsArray({
        shopId,
        layouts: updatedLayouts,
      }).then(() => {
        if (newLayoutName !== "") {
          // console.log(newLayoutName, updatedLayouts[0].hasOwnProperty(newLayoutName))
          const selectedLayout = updatedLayouts.find((layout) =>
            layout.hasOwnProperty(newLayoutName)
          );
          setLayout(selectedLayout[newLayoutName]);
        }
        queryClient.refetchQueries(["shop"]);
        alert("Layout(s) updated.");
      });
    }
  };

  const openEditContainerNameDialog = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );

    if (containerName === "dispatch") {
      alert("Dispatch container cannot be edited");
      return;
    }
    // CHECK IF CONTAINER IS EMPTY
    // const containerHasItems = containerToEdit.tags?.length > 0;

    // IF CONTAINER IS NOT EMPTY, PROMPT USER TO EMPTY CONTAINER FIRST
    // if (containerHasItems) {
    //   setContainerHasItemsWarningOpen(true);
    //   return;
    // }
    // IF CONTAINER IS EMPTY, EDIT CONTAINER
    setOriginalContainerName(containerName);
    setEditContainerOpen(true);
  };

  //HERE
  const editExistingContainer = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const layoutsClone = clone(layouts);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );
    const containerIndex = containersClone.findIndex(
      (container) => container.id === containerName
    );

    const editContainerNameFromLayouts = (name) => (layout) => {
      return Object.entries(layout).map(([key, value]) => ({
        [key]: value.map((v) =>
          v.i === name ? { ...v, i: editContainerName } : v
        ),
      }));
    };

    const containerClone = clone(containerToEdit);
    containerClone.id = editContainerName;
    containersClone[containerIndex] = containerClone;
    const payload = {};
    payload.shopId = shopId;
    payload.containers = containersClone;

    const updatedLayouts = layoutsClone
      .map(editContainerNameFromLayouts(containerName))
      .reduce((acc, curr) => [...acc, ...curr], []);

    updateContainers(payload).then(() => {
      const containerIds = containersClone.map((container) => container.id);
      setContainerIds(containerIds);
    });
    updateLayoutsArray({
      shopId,
      layouts: updatedLayouts,
    }).then(() => {
      if (newLayoutName !== "") {
        // console.log(newLayoutName, updatedLayouts[0].hasOwnProperty(newLayoutName))
        const selectedLayout = updatedLayouts.find((layout) =>
          layout.hasOwnProperty(newLayoutName)
        );
        setLayout(selectedLayout[newLayoutName]);
      }
      queryClient.refetchQueries(["shop"]);
      alert("Layout(s) updated.");
    });
  };

  const createContainer = () => {
    /*eslint no-console: 0*/
    if (containerIds.find((id) => id === newContainerName)) {
      alert("Cannot create container with same name as existing container");
      return;
    }

    if (newContainerName.length > 0) {
      const layoutCopy = layout.slice();
      const newLayout = layoutCopy.concat({
        i: newContainerName,
        x: 0,
        y: 0,
        w: 16,
        h: 6,
      });
      const payload = {};
      payload.shopId = shopId;
      payload.newContainerName = newContainerName;
      addContainerToContainers(payload);
      setLayout(newLayout);
      setNewContainerName("");
    }
  };

  const removeItem = (id) => {
    const layoutCopy = layout.slice();
    const newLayout = layoutCopy.filter((item) => item.i !== id);
    setLayout(newLayout);
  };

  const onLayoutChange = (layout) => {
    setLayout(layout);
  };

  const handleLayoutSelect = (layoutIndex) => {
    const newLayoutObj = layouts[layoutIndex];
    const keys = Object.keys(newLayoutObj);
    const layoutName = keys[0];
    setNewLayoutName(layoutName);
    const layout = newLayoutObj[keys[0]];
    setLayout(layout);
  };

  const handleLayoutDelete = () => {
    const layoutsClone = clone(layouts);
    const layoutNames = layouts.map((layout) => {
      const layoutName = Object.keys(layout)[0];
      return layoutName;
    });
    const layoutIndex = layoutNames.indexOf(newLayoutName);
    layoutsClone.splice(layoutIndex, 1);
    const payload = {};
    payload.shopId = shopId;
    payload.layouts = layoutsClone;
    overwriteLayoutsArray(payload).then(() => {
      alert("Layout deleted");
      setNewLayoutName("");
    });
  };

  const saveLayout = () => {
    // WRITE LAYOUTS TO FIRESTORE
    const layoutNames = layouts.map((layout) => {
      const layoutName = Object.keys(layout)[0];
      return layoutName;
    });
    const layoutAlreadyExists = layoutNames.find(
      (name) => name === newLayoutName
    );

    const payload = {};
    const collector = [];
    layout.forEach((containerObj) => {
      const newContainerObj = {};
      Object.keys(containerObj).forEach((key) => {
        if (containerObj[key] !== undefined) {
          newContainerObj[key] = containerObj[key];
        }
      });
      collector.push(newContainerObj);
    });
    payload.name = newLayoutName;
    payload.layout = collector;
    payload.shopId = shopId;
    if (layoutAlreadyExists === undefined) {
      addLayoutToLayouts(payload).then((ret) => {
        if (ret === "success") {
          queryClient.refetchQueries("shop");
          alert("Layout saved.");
        } else {
          alert("There was an error. Layout not saved.", ret);
        }
      });
    } else {
      const layoutsClone = clone(layouts);
      const layoutIndex = layoutNames.indexOf(newLayoutName);
      const newLayoutObj = { [newLayoutName]: collector };
      layoutsClone[layoutIndex] = newLayoutObj;
      const updatePayload = {};
      updatePayload.shopId = shopId;
      updatePayload.layouts = layoutsClone;
      // console.log(updatePayload);
      updateLayoutsArray(updatePayload).then((ret) => {
        queryClient.refetchQueries(["shop"]);
        alert("Layout saved.");
      });
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p>Having trouble with your layout? </p>
        <a
          style={{
            color: "red",
            textDecoration: "underline",
            marginLeft: ".5rem",
            marginRight: ".5rem",
          }}
          href="mailto:myhero@shopherodashboard.com"
        >
          {` Contact`}
        </a>
        <p>a Layout Hero.</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          paddingLeft: 100,
          paddingRight: 100,
          alignItems: "flex-start",
        }}
      ></div>
      {/* Whiteboard Configuration */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          paddingLeft: 100,
          paddingRight: 100,
          alignItems: "flex-start",
          marginTop: 40,
        }}
      >
        <div style={{ flex: 1, fontSize: 24, borderTop: "1px solid #707070" }}>
          Whiteboard Configuration
        </div>
      </div>
      <div
        style={{
          marginBottom: 30,
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          paddingLeft: 100,
          paddingRight: 100,
          alignItems: "flex-start",
          marginTop: 40,
        }}
      >
        <div style={{ flex: 1, flexDirection: "row" }}>
          <input
            type="text"
            placeholder="Container Name"
            value={newContainerName}
            onChange={(e) => setNewContainerName(e.target.value)}
            style={{
              width: 180,
              padding: 8,
              border: "1px solid #707070",
              borderRadius: 10,
              color: "black",
            }}
          />
          <button
            type="submit"
            onClick={createContainer}
            style={{
              marginTop: 4,
              width: 180,
              padding: 8,
              backgroundColor: "#244C91",
              color: "#ffffff",
              borderRadius: 10,
            }}
          >
            Create New Container
          </button>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "",
              marginBottom: "2rem",
            }}
          >
            <p style={{ marginRight: 10, padding: 10 }}>
              Select an existing layout to edit:{" "}
            </p>
            <select
              style={{
                color: "#000000",
                padding: 10,
                border: "1px solid gray",
                borderRadius: 10,
              }}
              onChange={(e) => handleLayoutSelect(e.target.value)}
              name="layout"
            >
              <option value="">Select...</option>
              {layouts.map((obj, index) => {
                const name = Object.keys(obj)[0];
                return (
                  <option key={name} value={index}>
                    {name}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ flex: 1, flexDirection: "row" }}></div>
        </div>

        <div style={{ flex: 1, flexDirection: "row" }}>
          <input
            type="text"
            value={newLayoutName}
            onChange={(e) => setNewLayoutName(e.target.value)}
            placeholder="Layout Name"
            name="layoutName"
            style={{
              width: 180,
              padding: 8,
              border: "1px solid #707070",
              borderRadius: 10,
              color: "black",
            }}
          />
          <button
            disabled={newLayoutName?.length === 0}
            type="submit"
            onClick={saveLayout}
            style={{
              marginTop: 4,
              width: 180,
              padding: 8,
              backgroundColor: newLayoutName.length === 0 ? "gray" : "#244C91",
              color: "#ffffff",
              borderRadius: 10,
            }}
          >
            Save Layout
          </button>
          <button
            disabled={newLayoutName?.length === 0}
            type="submit"
            onClick={handleLayoutDelete}
            style={{
              marginTop: 4,
              width: 180,
              padding: 8,
              backgroundColor: newLayoutName.length === 0 ? "gray" : "red",
              color: "#ffffff",
              borderRadius: 10,
            }}
          >
            Delete Layout
          </button>
        </div>
      </div>
      <div style={{ marginLeft: "100px" }}>
        <p style={{ fontWeight: "bold", paddingRight: 20 }}>
          Add an Existing Container to layout:
        </p>
        <div
          style={{
            paddingRight: 30,
            display: "flex",
            flex: "row",
            flexWrap: "wrap",
          }}
        >
          {containerIds.map((id) => {
            return (
              <div
                key={id}
                style={{
                  marginRight: "20px",
                  marginBottom: "10px",
                  minWidth: "275px",
                  maxWidth: "275px",
                  fontSize: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={(e) => {
                    createContainerFromExisting(id);
                  }}
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                    }}
                    src={addIcon}
                    alt="add to layout"
                    title="add this container to layout"
                  />
                </button>
                <button
                  onClick={(e) => {
                    deleteExistingContainer(id);
                  }}
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                    }}
                    src={removeIcon}
                    alt="delete container"
                    title="delete this container"
                  />
                </button>
                <button
                  onClick={(e) => {
                    openEditContainerNameDialog(id);
                  }}
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                    }}
                    src={editIcon}
                    alt="edit container name"
                    title="edit container name"
                  />
                </button>
                <div style={{ marginLeft: "10px" }}>{id}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ color: "black" }}>
        <GridLayout
          layout={layout}
          onLayoutChange={onLayoutChange}
          removeItem={removeItem}
        />
      </div>
      <EditContainerName
        editContainerName={editContainerName}
        setEditContainerName={setEditContainerName}
        editContainerOpen={editContainerOpen}
        setEditContainerOpen={setEditContainerOpen}
        editExistingContainer={editExistingContainer}
        originalContainerName={originalContainerName}
      />
      <ContainerHasItemsWarning
        containerHasItemsWarningOpen={containerHasItemsWarningOpen}
        setContainerHasItemsWarningOpen={setContainerHasItemsWarningOpen}
      />
    </>
  );
};
