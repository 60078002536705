import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { setCardsEntity } from "../Context/shop";
import { pushSubscriber } from "../Context/subscribers";
import { firestoreCard } from "../firestoreCard";

const onResult = (QuerySnapshot) => {
  // console.log("Cards Updated");
  // console.log("Cards QuerySnapshop", QuerySnapshot.docs.length);
  setCardsEntity(
    QuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: parseInt(doc.id) }))
  );
};

const onError = (error) => {
  console.error(error);
};

export const subscribeCards = async (shopId, statuses) => {
  const user = auth.currentUser;

  if (user?.uid) {
    const cardSubscriber = firestoreCard.subscribeAll(
      shopId,
      (cardDocs) => {
        // console.log("cardDocs", cardDocs);
        setCardsEntity(cardDocs);
      },
      statuses
    );

    pushSubscriber(cardSubscriber, "cards");
    return;
  }
  // console.log("Current user.uid does not exist for subscribeCards");
  return () => {};
};
