import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon as ExclamationIcon } from "@heroicons/react/24/outline";

export const ContainerHasItemsWarning = (props) => {
  const open = props.containerHasItemsWarningOpen;
  const setOpen = props.setContainerHasItemsWarningOpen;
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={{ position: "fixed", zIndex: 10, inset: 0, overflowY: "auto" }}
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            minHeight: "100vh",
            paddingTop: "16px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "80px",
            textAlign: "center",
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              style={{
                position: "fixed",
                inset: 0,
                backgroundColor: "rgba(75, 85, 99, 0.75)",
                transitionOpacity: "opacity 300ms ease-in-out",
              }}
            />
          </Transition.Child>

          <span
            style={{
              display: "none",
              verticalAlign: "middle",
              height: "100vh",
            }}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                verticalAlign: "bottom",
                backgroundColor: "white",
                borderRadius: "0.5rem",
                padding: "20px",
                textAlign: "left",
                overflow: "hidden",
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                transform: "translateY(0)",
                transition: "transform 300ms ease-out, opacity 300ms ease-out",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    margin: "auto",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "48px",
                    width: "48px",
                    borderRadius: "9999px",
                    backgroundColor: "#fecaca",
                  }}
                >
                  <ExclamationIcon
                    style={{ height: "24px", width: "24px", color: "#b91c1c" }}
                    aria-hidden="true"
                  />
                </div>
                <div
                  style={{
                    marginTop: "12px",
                    textAlign: "center",
                    marginLeft: "16px",
                  }}
                >
                  <Dialog.Title
                    as="h3"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.75rem",
                      fontWeight: "500",
                      color: "#111827",
                    }}
                  >
                    Container has items
                  </Dialog.Title>
                  <div style={{ marginTop: "8px" }}>
                    <p style={{ fontSize: "0.875rem", color: "#6b7280" }}>
                      You cannot edit or delete a container containing cards.
                      Please empty the container and try again.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  paddingLeft: "16px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                    borderRadius: "0.375rem",
                    padding: "8px 16px",
                    backgroundColor: "#dc2626",
                    fontSize: "1rem",
                    fontWeight: "500",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    border: "none",
                  }}
                  onClick={() => setOpen(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
