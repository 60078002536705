import { PropsWithChildren, useState } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { Toaster } from "../Base/Toaster";

export const BasePage = ({ children }: PropsWithChildren) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex flex-col h-screen overscroll-none">
      <div className="flex-shrink-0">
        {/* Header */}
        <Header onMenuClick={() => setSidebarOpen(!sidebarOpen)} />
      </div>

      <div className="flex flex-grow overflow-hidden bg-white relative">
        {/* Main Content and Drawer */}
        <main className="flex-1 flex-grow flex flex-col overflow-x-auto overflow-y-auto bg-primary text-primary-foreground">
          {children}
        </main>
        <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Toaster />
      </div>

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  );
};
