import { Label } from "../Base/Label";
import { Switch } from "../Base/Switch";

export interface EnableDisableSwitchProps {
  label: string;
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
  enabledText?: string;
  disabledText?: string;
}
export const EnableDisableSwitch = ({
  label,
  enabled,
  onChange,
  disabled,
  enabledText,
  disabledText,
}: EnableDisableSwitchProps) => (
  <div className="flex flex-row justify-between gap-2 w-96 items-center">
    <div>{label}</div>
    <div className="flex flex-row justify-between gap-2 items-center">
      <Label>{disabledText ?? "Disabled"}</Label>
      <Switch
        checked={enabled}
        onCheckedChange={onChange}
        disabled={disabled}
      />
      <Label>{enabledText ?? "Enabled"}</Label>
    </div>
  </div>
);
