import { useStore } from "@/stores/useStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardConfigPage from "./CardConfig.page";
import CompanyInfoPage from "./CompanyInfo.page";
import EmployeesPage from "./Employees.page";
import IntegrationsPage from "./Integrations.page";
import LayoutBuilderPage from "./LayoutBuilder.page";
import ShopDashboardPage from "./ShopDashboard.page";
import TermsAndConditionsPage from "./TermsAndConditions.page";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const shopId = useStore((s) => s.shopId);
  const user = useStore((s) => s.user);
  const allowedShops = useStore((s) => s.allowedShops);
  const setCurrentShopId = useStore((s) => s.setCurrentShopId);

  const handleShopChange = (shopId: string) => {
    setCurrentShopId(shopId);
  };

  useEffect(() => {
    if (shopId) {
      navigate(`/${shopId}`);
    }
  }, [shopId, navigate]);

  return (
    <div className="flex flex-col gap-2 max-w-screen-md mx-auto">
      <div className="mt-4">
        <h1 className="text-3xl">Welcome {user && user.displayName}</h1>
        <p className="mt-2">Select a shop to view your dashboard.</p>
        {allowedShops?.length > 0 && (
          <div className="mt-5 w-full mr-4">
            <p>Active Shop:</p>
            <Select onValueChange={handleShopChange} name="shopId">
              <SelectTrigger className="w-48">
                <SelectValue placeholder="Select a Shop" />
              </SelectTrigger>
              <SelectContent>
                {allowedShops
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((s) => (
                    <SelectItem key={s.shopId} value={`${s.shopId}`}>
                      {s.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        )}
      </div>
    </div>
  );
};

export {
  CardConfigPage,
  CompanyInfoPage,
  EmployeesPage,
  IntegrationsPage,
  LayoutBuilderPage,
  ShopDashboardPage,
  TermsAndConditionsPage,
};
