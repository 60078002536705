import { ReactNode } from "react";
import {
  Tooltip as BaseTooltip,
  TooltipContent,
  TooltipTrigger,
} from "../Base/Tooltip";

type ToolTipProps = {
  message: string;
  children: ReactNode;
};

export default function Tooltip({ message, children }: ToolTipProps) {
  return (
    <BaseTooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>{message}</TooltipContent>
    </BaseTooltip>
  );
}
