import { TERMS } from "@/lib/terms";

const TermsAndConditionsPage = () => {
  return (
    <div className="p-5 max-w-screen-lg flex flex-col items-center text-justify mx-auto mb-20">
      <div>
        <h1 className="text-2xl text-center mb-4">Terms of Service</h1>
        <div dangerouslySetInnerHTML={{ __html: TERMS }} />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
