import { Employee } from "@/types/v2";

export function getEmployeeName(
  employeeId: string | null | undefined,
  employeesData: Employee[]
) {
  if (!employeeId) return ""; // Check if employeeId is null or undefined

  const employee = employeesData?.find(
    (employee) => employee.id === employeeId
  );
  if (!employee) return "Unknown"; // Return "Unknown" if no matching employee is found

  return `${employee.firstName} ${employee.lastName}`; // Combine first and last names
}

export function getEmployeeInitials(
  employeeId: string | null | undefined,
  employeesData: Employee[]
) {
  if (!employeeId) return ""; // Check if employeeId is null or undefined

  const employee = employeesData?.find(
    (employee) => employee.id === employeeId
  );
  if (!employee) return ""; // Return an empty string if no matching employee is found

  const firstInitial = employee?.firstName?.[0] ?? ""; // Safely access firstName[0] or return an empty string
  const lastInitial = employee?.lastName?.[0] ?? ""; // Safely access lastName[0] or return an empty string

  return `${firstInitial}${lastInitial}`; // Combine initials
}

export function getServiceAdvisorColor(
  employeeId: string | null | undefined,
  employeesData: Employee[]
) {
  const fixedColors = [
    "#001f3f", // Dark Blue
    "#006400", // Dark Green
    "#FF8C00", // Dark Orange
    "#4B0082", // Dark Purple
    "#800000", // Dark Maroon
    "#8B0000", // Dark Red
    "#2F4F4F", // Dark Gray
    "#D87093", // Dark Pink
    "#8B4513", // Dark Brown
    "#0C0C0C", // Almost Black
    // Additional colors, darker and less vibrant
    "#661616",
    "#663716",
    "#665716",
    "#667716",
    "#4c6616",
    "#3c6616",
    "#2c6616",
    "#165221",
    "#165241",
    "#165261",
    "#165282",
  ];

  if (!employeeId) return "#333"; // Default color for undefined/null employeeId

  const employeeIndex = employeesData?.findIndex(
    (employee) => employee.id === employeeId
  );

  if (employeeIndex === -1 || employeeIndex === undefined) return "#333"; // Return default if employee not found

  const colorIndex = employeeIndex % fixedColors.length;

  return fixedColors[colorIndex];
}
