import { CARD_FIELD_OPTIONS } from "@/types/v2";

export const DEFAULT_CARD_PRIMARY_FIELD = CARD_FIELD_OPTIONS[0];
export const DEFAULT_CARD_SECONDARY_FIELD = CARD_FIELD_OPTIONS[1];
export const DEFAULT_CARD_TERTIARY_FIELD = CARD_FIELD_OPTIONS[5];

export const TEKMETRIC_STATUSES = [
  { value: 1, label: "Estimate" },
  { value: 2, label: "Work-In-Progress" },
  { value: 3, label: "Complete" },
  { value: 4, label: "Saved for later" },
  { value: 8, label: "Accounts Receivable" },
];

export const DEFAULT_TIMER_VALUE = "15";

export const DEFAULT_CARD_LABEL_WRAP = "no";
export const DEFAULT_CARD_TIMER_UNITS = "minutes";

export const COLOR_PICKER_COLORS = [
  {
    hex: "#FF7B7B",
    label: "cpRed",
  },
  {
    hex: "#FFD18D",
    label: "cpLightOrange",
  },
  {
    hex: "#CCFF1D",
    label: "cpYellow",
  },
  {
    hex: "#65FF60",
    label: "cpGreen",
  },
  {
    hex: "#A8B4FF",
    label: "cpPurple",
  },
  {
    hex: "#C880FF",
    label: "cpVibrantPurple",
  },
  {
    hex: "#47FFFF",
    label: "cpCyan",
  },
  {
    hex: "#FF85D0",
    label: "cpPink",
  },
  {
    hex: "#FFD700",
    label: "cpGold",
  },
  {
    hex: "#FFA500",
    label: "cpOrange",
  },
  {
    hex: "#A9A9A9",
    label: "cpDarkGrey",
  },
  {
    hex: "#E2E2E2",
    label: "cpLightGrey",
  },
];

export const DEFAULT_TIMER_RUNNING_UNDER = "#FFFF00";
export const DEFAULT_TIMER_RUNNING_OVER = "#FF0000";

export const DEFAULT_CARD_COLOR = "#E2E2E2";
export const PROMISE_TIME_PAST_COLOR = "#FF7B7B";

export const DEFAULT_PAGINATION_SIZE = 25;
