import { useField } from "formik";
import { Input, InputProps } from "@/components/Base/Input.tsx";
import { Label } from "@/components/Base/Label.tsx";
import { Error } from "@/components/Base/Error.tsx";

interface FormikInputProps extends InputProps {
  name: string;
  type: string;
  placeholder?: string;
  label?: string;
}

export const FormikInput = (props: FormikInputProps) => {
  const [field, meta] = useField(props);
  const { label } = props;
  return (
    <div>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <Input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Error error={meta.error} className="mt-1" />
      ) : null}
    </div>
  );
};
