import { useState } from "react";
import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";

interface CardRemoveDialogProps {
  onRemove: () => void;
}
export const CardRemoveDialog = ({ onRemove }: CardRemoveDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleContinue = () => {
    onRemove();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>Remove Card</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove</DialogTitle>
        </DialogHeader>
        <div>
          This will remove the card from the dashboard. Are you sure you want to
          do this?
        </div>
        <DialogFooter>
          <Button type="button" variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant={"destructive"}
            onClick={handleContinue}
          >
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
