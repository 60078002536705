import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const overwriteLayoutsArray = async (payload) => {
  const shopRef = doc(db, "shops", payload.shopId);

  const returnValue = await updateDoc(shopRef, { layouts: payload.layouts })
    .then(() => {
      return "success";
    })
    .catch((err) => {
      console.error("overwriteLayoutsArray error", err);
      return err;
    });
  return returnValue;
};
