import { firestore } from "@/services/firestore";
import { Card, CreateCardDto, Job, PatchCardDto } from "@/types/v2";
import { Card as CardV1, Job as JobV1 } from "@/types/v1";
import shortUUID from "short-uuid";
import { addCreationData } from "@/lib/database";
import { customer } from "@/models/customer";
import { vehicle } from "@/models/vehicle";
import { DEFAULT_TIMER_VALUE } from "@/lib/const";
import { createConverter } from "@/models/common";
import { httpsCallables } from "@/models/httpsCallables";

export const card = {
  get: async (shopId: string, cardId: string) => {
    return firestore.getDocument<Card>(
      `shops/${shopId}/cards/${cardId}`,
      createConverter<CardV1, Card>(card)
    );
  },
  subscribeAll: (
    id: string,
    statuses: (number | string)[],
    callback: (doc: Card[] | undefined) => void
  ) => {
    return firestore.subscribeCollectionWQueries<Card>(
      `shops/${id}/cards`,
      callback,
      [
        {
          searchParam: "statusEnum",
          opsString: "in",
          equality: [...statuses, "manuallyCreated"],
        },
        {
          searchParam: "status",
          opsString: "in",
          equality: [...statuses, "manuallyCreated"],
        },
        {
          searchParam: "repairOrderStatus" as keyof Card,
          opsString: "in",
          equality: [
            ...statuses.map((s) => `${s}`),
            ...statuses.map((s) => parseInt(`${s}`)),
            "manuallyCreated",
          ],
        },
      ],
      createConverter<CardV1, Card>(card)
    );
  },
  create: async (shopId: string, card: CreateCardDto) => {
    const fullCard = addCreationData(
      {
        id: shortUUID.generate(),
        timers: [],
        createdVia: "dashboard",
        status: "manuallyCreated",
        currentContainer: "dispatch",
        ...card,
      },
      "client"
    );
    try {
      const newCard = await firestore.createDocument(
        `shops/${shopId}/cards/${fullCard.id}`,
        fullCard
      );
      // Update shop dispatch container with new ID
      await httpsCallables.moveCard({
        cardId: newCard.id,
        shopId,
        toContainerId: "dispatch",
      });
      return newCard;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  update: async (shopId: string, cardId: string, cardData: PatchCardDto) => {
    if (cardData._isSoftMigrated) {
      const allCardData = await card.get(shopId, cardId);
      cardData = { ...allCardData, ...cardData, shopId: shopId };
      delete cardData.createdAt;
      delete cardData.updatedAt;
      delete cardData.sourceLastUpdated;
    }
    delete cardData._isSoftMigrated;
    try {
      return await firestore.updateDocument(
        `shops/${shopId}/cards/${cardId}`,
        cardData
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  markImportant: async (shopId: string, cardId: string, isStarred: boolean) => {
    return card.update(shopId, cardId, { isStarred: isStarred });
  },
  isV1: (card: CardV1 | Card): card is CardV1 => {
    return (card as Card).schemaVersion === undefined;
  },
  transformV1ToV2: (cardData: CardV1): Card => {
    return {
      ...cardData,
      _deleted: false,
      _isSoftMigrated: true,
      isWaiter: false,
      schemaVersion: 2,
      promisedTime: cardData.repairOrder?.customerTimeOut ?? "",
      primaryField: cardData.primaryField as Card["primaryField"],
      secondaryField: cardData.secondaryField as Card["secondaryField"],
      tertiaryField:
        (cardData.tertinaryField as Card["tertiaryField"]) ??
        (cardData as unknown as Card)["tertiaryField"],
      id: cardData.id.toString(),
      shopId: `${cardData.customer?.shopId}`,
      status: cardData.repairOrderStatus?.toString() ?? "unknown",
      statusEnum: `${cardData.repairOrderStatus}`,
      source: cardData.createdVia === "dashboard" ? "manual" : "tekmetric",
      backgroundColor: cardData.color ?? cardData.bgColor ?? "",
      notes: cardData.notes ?? [],
      customer:
        cardData.customer && customer.isV1(cardData.customer)
          ? customer.transformV1ToV2(cardData.customer)
          : {},
      vehicle:
        cardData.vehicle && vehicle.isV1(cardData.vehicle)
          ? vehicle.transformV1ToV2(cardData.vehicle)
          : {},
      technicianId: `${cardData.technicianId ? cardData.technicianId : ""}`,
      technicianIds: cardData.technicianIds
        ? cardData.technicianIds.map((techId) => `${techId}`)
        : [],
      serviceWriterId: `${cardData.serviceWriterId ? cardData.serviceWriterId : ""}`,
      label: "",
      sourceLastUpdated: new Date(),
      isStarred: cardData.isStarred ?? false,
      currentContainer: cardData.currentContainer,
      color: cardData.color ?? cardData.bgColor ?? "",
      timer: card.isV1(cardData)
        ? { timeLimitInMinutes: DEFAULT_TIMER_VALUE }
        : (cardData as Card).timer,
      timers: cardData.timers,
      keyTag: cardData.keytag ? `${cardData.keytag}` : null,
      cardNumber: cardData.repairOrderNumber
        ? `${cardData.repairOrderNumber}`
        : null,
      jobs: cardData.repairOrder?.jobs
        ? (cardData.repairOrder.jobs.map(
            (job: JobV1) =>
              ({
                technicianId: job.technicianId ? `${job.technicianId}` : null,
                loggedHours: job.loggedHours,
                laborHours: job.laborHours,
                laborTotal: job.laborTotal,
                completedDate: job.completedDate,
                name: job.name,
              }) as Job
          ) as Job[])
        : [],
    };
  },
  subscribe: (
    shopId: string,
    id: string,
    callback: (doc: Card | undefined) => void
  ) => {
    console.log(`subscribing to ${id} on shop ${shopId}`);
    return firestore.subscribeDocument<Card>(
      `shops/${shopId}/cards/${id}`,
      callback,
      createConverter<CardV1, Card>(card)
    );
  },
};
