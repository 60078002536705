import { user } from "@/models/user";
import { Unsubscribe } from "firebase/firestore";
import { StateCreator } from "zustand";
import { User as AuthUser } from "firebase/auth";
import { AuthCustomClaims, Shop } from "@/types/v2";
import { Role, User } from "@/types/v1";

export interface UserSlice {
  user: User | undefined;
  allowedShops: Shop[];
  subscribeUser: (authUser: AuthUser) => Promise<void>;
  userSubscription: Unsubscribe | undefined;
  unsubscribeUser: () => void;
  resetUser: () => void;
}

const initialSlice: UserSlice = {
  user: undefined,
  allowedShops: [],
  userSubscription: undefined,
  subscribeUser: async () => {},
  unsubscribeUser: () => {},
  resetUser: () => {},
};

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set,
  get
) => ({
  ...initialSlice,
  resetUser: () => {
    set({ ...initialSlice });
  },

  subscribeUser: async (authUser: AuthUser) => {
    // Check if user in DB, if not, create
    const dbUser = await user.getById(authUser.uid);
    const tokenResult = await authUser.getIdTokenResult();
    const claims = tokenResult.claims as Partial<AuthCustomClaims> | undefined;
    const role = (
      !claims?.user && claims?.superAdmin
        ? "superAdmin"
        : claims?.role
          ? (claims.role ?? "user")
          : "user"
    ) as Role;

    if (!dbUser) {
      await user.create({
        id: authUser.uid,
        uid: authUser.uid,
        displayName: authUser.displayName ?? "",
        email: authUser.email ?? "",
        emailVerified: authUser.emailVerified,
        creationTime: authUser.metadata.creationTime,
        lastLoginTime: new Date(),
        role: role ?? "user",
      });
    } else {
      await user.update({
        id: authUser.uid,
        uid: authUser.uid,
        lastLoginTime: new Date(),
        role: role ?? "user",
      });
    }

    const subscription = user.subscribe(authUser.uid, async (doc) => {
      if (!doc) {
        set({ user: undefined, allowedShops: [] });
        return;
      }
      const allowedShops = await user.getLinkedShops(doc.id, role);

      set({
        user: doc,
        allowedShops: allowedShops.sort((a, b) => a.name.localeCompare(b.name)),
      });
    });
    set({ userSubscription: subscription });
    return;
  },

  unsubscribeUser: () => {
    const unsubscribe = get().userSubscription;
    if (unsubscribe) unsubscribe();
    set({ userSubscription: undefined });
  },
});
