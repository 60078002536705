import { Integrators } from "./Integrator";
import { BaseFirestore } from "./Firestore";

export const CARD_FIELD_OPTIONS = [
  "RO Number",
  "Customer Last Name",
  "Customer Name",
  "Key Tag",
  "Year, Make, Model of vehicle",
  "YY Model",
] as const;

export const CARD_FIELDS = [
  { key: "primaryField", label: "Primary Field" },
  { key: "secondaryField", label: "Secondary Field" },
  { key: "tertiaryField", label: "Tertiary Field" },
] as const;

export type CardFieldKeys = (typeof CARD_FIELDS)[number]["key"];

export type CardFieldKey = (typeof CARD_FIELD_OPTIONS)[number];

export type CardContents = {
  primaryField?: CardFieldKey;
  secondaryField?: CardFieldKey;
  tertiaryField?: CardFieldKey;
};

export type Tag = {
  id: string;
  retentionType: "manual" | "auto";
};
export type Container = {
  id: string;
  tags: string[];
  displayName?: string;
  linkedTechnicianId?: string | number | string[];
  linkedSourceLabel?: string | string[];
};

type Features = { [x: string]: boolean };

export type Layout = {
  h: number;
  i: string;
  moved: boolean;
  static: boolean;
  w: number;
  x: number;
  y: number;
};

export type Layouts = { [x: string]: Layout[] }[];

type CardConfig = {
  cardContents: CardContents;
  timerUnits?: "minutes" | "hours";
};

export type SourceShop = {
  _source?: BaseFirestore["_source"];
  schemaVersion: 2;
  id?: string;
  shopId: string;
  email: string;
  name: string;
  phone: string;
};
export type Shop = SourceShop & {
  _isSoftMigrated?: boolean;
  cardConfig: CardConfig;
  containers: Container[];
  layouts: Layouts;
  disabled?: boolean;
  createdOn: string;
  defaultTimer: string;
  enabledFeatures: Features;
  lastSyncedTimestamp: number;
  refreshTimeInSeconds: number;
  website?: string;
  logoUrl?: string;
  customLabels?: string[];
  repairOrders: string[];
  statusesToSync: string[];
  integration?: Integrators;
  integrationKey?: "dev" | "prod";
  cardLabelWrap?: string;
  users?: string[];
  adminUserIds?: string[];
  technicianUserIds?: string[];
  officeUserIds?: string[];
  viewOnlyUserIds?: string[];
};
