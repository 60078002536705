import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const spinnerVariants = cva(
  "inline-block animate-spin rounded-full border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
  {
    variants: {
      variant: {
        default: "text-surface dark:text-white",
      },
      size: {
        default: "h-8 w-8 border-4",
        sm: "h-4 w-4 border-2",
        lg: "h-16 w-16 border-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export type SpinnerProps = VariantProps<typeof spinnerVariants>;

export const Spinner = ({ size, variant }: SpinnerProps) => {
  return (
    <div className={cn(spinnerVariants({ variant, size }))} role="status">
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
};
