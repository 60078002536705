import packageJson from "../package.json";
import buildJson from "../build.json";

const config = {
  version: packageJson.version,
  build: buildJson.buildNumber,
  commitHash: __COMMIT_HASH__,
  mode: import.meta.env.MODE ?? "dev",
  env: import.meta.env.VITE_ENVIRONMENT,
  emulator: import.meta.env.MODE === "emulator",
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  },
  integrations: {
    tekmetric: {
      enabled: true,
      urls: {
        links: {
          dev: import.meta.env.VITE_TEKMETRIC_DEV_URL,
          prod: import.meta.env.VITE_TEKMETRIC_URL,
        },
        api: {
          dev: import.meta.env.VITE_TEKMETRIC_DEV_API_URL,
          prod: import.meta.env.VITE_TEKMETRIC_API_URL,
        },
        apiKeys: {
          dev: import.meta.env.VITE_TEKMETRIC_DEV_API_KEY,
          prod: import.meta.env.VITE_TEKMETRIC_API_KEY,
        },
      },
    },
  },
  availableFeatureFlags: {
    isCardColorEnabled:
      import.meta.env.VITE_FF_ISCARDCOLORENABLED_ACTIVE === "true",
    isCardLaborEnabled:
      import.meta.env.VITE_FF_ISCARDLABORENABLED_ACTIVE === "true",
    isCardPrimaryLinkEnabled:
      import.meta.env.VITE_FF_ISCARDPRIMARYLINKENABLED_ACTIVE === "true",
    isPromiseTimeEnabled:
      import.meta.env.VITE_FF_ISPROMISETIMEENABLED_ACTIVE === "true",
    isServiceAdvisorFilterEnabled:
      import.meta.env.VITE_FF_ISSERVICEADVISORFILTERENABLED_ACTIVE === "true",
    isTechnicianFilterEnabled:
      import.meta.env.VITE_FF_ISTECHNICIANFILTERENABLED_ACTIVE === "true",
    isSyncV2Enabled: import.meta.env.VITE_FF_ISSYNCV2ENABLED_ACTIVE === "true",
    isTechnicianLinkEnabled:
      import.meta.env.VITE_FF_ISTECHNICIANLINKENABLED_ACTIVE === "true",
    isROLabelLinkEnabled:
      import.meta.env.VITE_FF_ISROLLABELLINKENABLED_ACTIVE === "true",
    isCardColorSyncEnabled:
      import.meta.env.VITE_FF_ISCARDCOLORSYNCENABLED_ACTIVE === "true",
    isManualCardOnlyEnabled:
      import.meta.env.VITE_FF_ISMANUALCARDONLYENABLED_ACTIVE === "true",
    isManualCardsEnabled:
      import.meta.env.VITE_FF_ISMANUALCARDSENABLED_ACTIVE === "true",
    isCardNotesEnabled: import.meta.env.VITE_FF_CARDNOTES_ACTIVE === "true",
  },
};

console.log({ config, env: import.meta.env });
export { config };
