import { Button } from "@/components/Base/Button";
import { Label } from "@/components/Base/Label";
import { Textarea } from "@/components/Base/Textarea";
import { Card, Note } from "@/types/v2";
import { BanIcon, PencilIcon, SendIcon, Trash2Icon } from "lucide-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { addNote, deleteNote, updateNote } from "@/lib/cards";
import { cn, firestoreToDateTime, getInitials } from "@/lib/utils";
import { useUserQuery } from "@/hooks/queries/useUserQuery";
import { useState } from "react";
import Tooltip from "@/components/Common/Tooltip";
import { Spinner } from "@/components/Common/Spinner";

interface CardNoteProps {
  note: Note;
  shopId: string;
  cardData: Card; // Assuming you'll need this for the onSubmit function
  disabled?: boolean;
}

const CardNote = ({ note, shopId, cardData, disabled }: CardNoteProps) => {
  const { isLoading: isLoadingCreatedBy, data: author } = useUserQuery(
    note.createdBy
  );
  const { isLoading: isLoadingUpdatedBy, data: editor } = useUserQuery(
    note.updatedBy ?? ""
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noteText, setNoteText] = useState(note.text);

  const handleDelete = () => {
    setIsSubmitting(true);
    return deleteNote(shopId, note.id, cardData.id, cardData).finally(() =>
      setIsSubmitting(false)
    );
  };
  const handleUpdate = () => {
    setIsSubmitting(true);
    return updateNote(shopId, note.id, noteText, cardData.id, cardData)
      .then(() => setIsEditing(false))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="flex flex-col gap-2 border shadow-lg rounded-md p-2 bg-white">
      {!isEditing ? (
        <p>{note.text}</p>
      ) : (
        <Textarea
          id="editedNote"
          name="editedNote"
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
        />
      )}
      <div className="border-t-2"></div>
      <div className="flex flex-row justify-between gap-2">
        <div className="flex flex-row">
          {author && (
            <div className="relative inline-block mt-1">
              <Tooltip
                message={`Created by ${author.displayName} - ${firestoreToDateTime(note.createdAt)?.toLocaleString()}`}
              >
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-gray-800 text-xs">
                  {getInitials(author.displayName)}
                </div>
              </Tooltip>
            </div>
          )}

          {editor && (
            <div className="relative inline-block mt-1">
              <Tooltip
                message={`Edited by ${editor.displayName} - ${firestoreToDateTime(note.updatedAt)?.toLocaleString()}`}
              >
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-gray-800 text-xs">
                  {getInitials(editor.displayName)}
                </div>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="flex flex-row">
          {isDeleting && <p className="text-red mt-2">Delete note?</p>}
          {!isEditing && (
            <Tooltip message={`Delete Note`}>
              <Button
                variant={"ghost"}
                disabled={isLoadingCreatedBy || isLoadingUpdatedBy || disabled}
                size="icon"
                className={cn(
                  "rounded-full",
                  isDeleting ? "hover:bg-slate-300" : ""
                )}
                onClick={() =>
                  isDeleting ? handleDelete() : setIsDeleting(true)
                }
              >
                <Trash2Icon className={cn("", isDeleting ? "text-red" : "")} />
              </Button>
            </Tooltip>
          )}

          {(isEditing || isDeleting) && (
            <Tooltip message={"Cancel"}>
              <Button
                variant={"ghost"}
                disabled={
                  isLoadingCreatedBy ||
                  isLoadingUpdatedBy ||
                  isSubmitting ||
                  disabled
                }
                size="icon"
                className="rounded-full"
                onClick={() => {
                  setIsEditing(false);
                  setIsDeleting(false);
                }}
              >
                {isSubmitting && isDeleting ? <Spinner /> : <BanIcon />}
              </Button>
            </Tooltip>
          )}

          {!isDeleting && (
            <Tooltip message={isEditing ? "Update note" : "Edit"}>
              <Button
                variant={"ghost"}
                disabled={
                  isLoadingCreatedBy ||
                  isLoadingUpdatedBy ||
                  isSubmitting ||
                  disabled
                }
                size="icon"
                className="rounded-full"
                onClick={() =>
                  isEditing ? handleUpdate() : setIsEditing(true)
                }
              >
                {isSubmitting && isEditing ? (
                  <Spinner />
                ) : isEditing ? (
                  <SendIcon />
                ) : (
                  <PencilIcon />
                )}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const CardNotes = ({ cardData, ...props }: NotesMainProps) => {
  return (
    <div className="flex flex-col w-full overflow-y-scroll p-2 gap-2">
      {cardData.notes?.map((note) => (
        <CardNote key={note.id} note={note} cardData={cardData} {...props} />
      ))}
    </div>
  );
};

export interface NotesMainProps {
  shopId: string;
  cardData: Card;
  disabled?: boolean;
}

export const NotesMain = ({ shopId, cardData, disabled }: NotesMainProps) => {
  const validationSchema = Yup.object().shape({
    notes: Yup.string().min(1).required("Notes are required"),
  });

  return (
    <div className="flex flex-col gap-2 w-full max-h-96">
      <Formik
        initialValues={{ notes: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          addNote(shopId, values.notes, cardData.id, cardData)
        }
      >
        {({ values, handleSubmit, handleChange, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col relative">
              <Label className="mb-2" htmlFor="notesInput">
                Add a note to this RO
              </Label>
              <Textarea
                disabled={disabled}
                id="notes"
                name="notes"
                placeholder="Notes"
                value={values.notes}
                onChange={handleChange}
              />
              <div className="absolute bottom-1 right-1">
                <Button
                  type="submit"
                  variant={"ghost"}
                  disabled={!isValid || isSubmitting || disabled}
                  size="icon"
                  className="rounded-full"
                >
                  <SendIcon />
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {cardData.notes && (
        <CardNotes cardData={cardData} shopId={shopId} disabled={disabled} />
      )}
    </div>
  );
};
