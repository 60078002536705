import { EllipsisIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../Base/Popover";
import { Button } from "../Base/Button";
import { RenameContainerDialog } from "./RenameContainerDialog";
import { useState } from "react";
import { LinkTechnicianDialog } from "./LinkTechnicianDialog";
import { Feature } from "flagged";
import { LinkCustomLabelDialog } from "./LinkCustomLabelDialog";

interface ContainerMenuProps {
  containerId: string;
  containerName: string;
  technicianId?: string | string[];
  sourceLabel?: string | string[];
  removeItem: (id: string) => void;
  removeOnly?: boolean;
}
export const ContainerMenu = ({
  containerId,
  containerName,
  technicianId,
  sourceLabel,
  removeItem,
  removeOnly,
}: ContainerMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleRemoveItem = () => {
    removeItem(containerId);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className="absolute right-1 top-0 cursor-pointer z-5 p-2 rounded-lg text-accent"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          console.log("click");
          e.stopPropagation();
        }}
      >
        <EllipsisIcon />
      </PopoverTrigger>
      <PopoverContent onMouseDown={(e) => e.stopPropagation()} className="w-72">
        <div className="w-full flex flex-col gap-2">
          <Button onClick={handleRemoveItem}>Remove Container</Button>
          <RenameContainerDialog
            onClose={() => setOpen(false)}
            containerId={containerId}
            containerName={containerName}
          />
          {!removeOnly && (
            <>
              <Feature name="isTechnicianLinkEnabled">
                <LinkTechnicianDialog
                  onClose={() => setOpen(false)}
                  containerId={containerId}
                  technicianId={technicianId}
                />
              </Feature>
              <Feature name="isROLabelLinkEnabled">
                <LinkCustomLabelDialog
                  onClose={() => setOpen(false)}
                  containerId={containerId}
                  sourceLabel={sourceLabel}
                />
              </Feature>
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
