import { ActivityFeed } from "@/types/v2";
import { firestore } from "@/services/firestore";

export const ActivityFeedRepository = {
  subscribeAll: (
    shopId: string,
    callback: (doc: ActivityFeed[] | undefined) => void
  ) => {
    return firestore.subscribeCollection<ActivityFeed>(
      `shops/${shopId}/activityFeed`,
      callback,
      undefined,
      250,
      "createdAt",
      "desc"
    );
  },
};
