import { Layout } from "@/types/v2";
import { shop } from "@/models/shop";
import { updateDoc, arrayUnion } from "firebase/firestore";

export const layout = {
  generateBaseLayout: () => {
    return [{ i: "dispatch", x: 0, y: 0, w: 96, h: 2 }];
  },
  addLayout: (shopId: string, layoutName: string, layout: Layout) => {
    return updateDoc(shop.ref(shopId), {
      layouts: arrayUnion({ [layoutName]: layout }),
    });
  },
};
