import { Button } from "@/components/Base/Button";
import { Input } from "@/components/Base/Input";
import { Heading } from "@/components/Common/Heading";
import { cn } from "@/lib/utils";
import { useStore } from "@/stores/useStore";
import { useState } from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { authError, login } = useStore();

  const handleSignIn = () => {
    login(email, password);
  };

  return (
    <div className="flex flex-col gap-4 bg-primary text-primary-foreground m-auto">
      <Heading>Login to Get Started</Heading>
      <div className="w-96 flex flex-row items-center">
        <label className="w-32" htmlFor="email">
          Email:
        </label>
        <Input
          className="text-primary"
          type="text"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="w-96 flex flex-row items-center">
        <label className="w-32" htmlFor="password">
          Password:{" "}
        </label>
        <Input
          className="text-primary"
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className={cn("w-96 flex pb-4", !authError && "invisible")}>
        <div className="w-128 text-destructive">{authError}</div>
      </div>

      <div className="my-4">
        Forgot your password? <Link to="/resetpassword">Click Here</Link>
      </div>

      <Button variant={"accent"} onClick={handleSignIn}>
        Sign In
      </Button>
    </div>
  );
};

export default LoginPage;
