import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";

export const addLayoutToLayouts = async (payload) => {
  const shopRef = doc(db, "shops", payload.shopId);
  const name = payload.name;
  const layout = payload.layout;
  const layoutObj = { [name]: layout };

  const returnValue = await updateDoc(shopRef, {
    layouts: arrayUnion(layoutObj),
  })
    .then(() => {
      return "success";
    })
    .catch((err) => {
      console.error("addLayoutToLayouts error", err);
      return err;
    });
  return returnValue;
};
