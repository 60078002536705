import { firestore } from "@/services/firestore";
import { storage } from "@/services/storage";
import { Unsubscribe } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { StateCreator } from "zustand";
import { config } from "@/config";
import { employee } from "@/models/employee";
import { KEYS, localStorageManager } from "@/services/localStorage";
import { featureFlags } from "@/models/featureFlags";
import { sentry } from "@/services/sentry";
import { UserSlice } from "./user.slice";
import { FeatureFlagEnabled, Timer } from "@/types/v1";
import { httpsCallables } from "@/models/httpsCallables";
import {
  Card,
  Employee,
  SyncDriver,
  Shop,
  Integration,
  EmployeeRole,
  ActivityFeed,
} from "@/types/v2";
import { firestoreToDateTime } from "@/lib/utils";
import { card } from "@/models/cards";
import { shop } from "@/models/shop";
import { localStorage } from "@/models/localStorage";
import { user } from "@/models/user";
import { AuthSlice } from "@/stores/auth.slice";
import { ActivityFeedRepository } from "@/models/activityFeed";

export interface ShopSlice {
  shop: Shop | undefined;
  shopId: string | undefined;
  integration: Integration | undefined;
  setIntegration: () => void;
  logoUrl: string | undefined;
  logoExists: boolean;
  setShopRole: (role: EmployeeRole) => void;
  subscribeShop: (id: string) => void;
  shopSubscription: Unsubscribe | undefined;
  unsubscribeShop: () => void;
  getLogoUrl: () => Promise<void>;
  dashboardServiceWriterId: string | undefined;
  setDashboardServiceWriterId: (id: string) => void;
  dashboardTechnicianId: string | undefined;
  setDashboardTechnicianId: (id: string) => void;
  dashboardLayoutId: string | undefined;
  setDashboardLayoutId: (id: string) => void;
  refreshTimeInSeconds: number;
  isSyncing: boolean;
  lastSyncedTime: number;
  syncButtonText: string | undefined;
  syncError: boolean;
  timers: { [x: string]: Timer } | undefined;
  subscribeTimers: () => void;
  timersSubscription: Unsubscribe | undefined;
  employees: Employee[] | undefined;
  subscribeEmployees: () => void;
  employeesSubscription: Unsubscribe | undefined;
  unsubscribeEmployees: () => void;
  setCurrentShopId: (id: string | null) => void;
  featureFlags: FeatureFlagEnabled | undefined;
  setFeatureFlags: () => void;
  syncWithSource: (force?: boolean) => void;
  syncDriver: SyncDriver | undefined;
  subscribeSyncDriver: () => void;
  syncDriverSubscription: Unsubscribe | undefined;
  unsubscribeSyncDriver: () => void;
  containers: Shop["containers"];
  layouts: Shop["layouts"];
  defaultTimer: Shop["defaultTimer"];
  cardContents: Shop["cardConfig"]["cardContents"];
  statuses: Shop["statusesToSync"];
  lastSyncedTimestamp: Shop["lastSyncedTimestamp"];
  refreshRate: Shop["refreshTimeInSeconds"];
  cards: Card[];
  subscribeCards: () => void;
  cardSubscription: Unsubscribe | undefined;
  unsubscribeCards: () => void;
  shopRole: EmployeeRole | "superAdmin" | undefined;
  activityFeed: ActivityFeed[];
  subscribeActivityFeed: () => void;
  activityFeedSubscription: Unsubscribe | undefined;
  unsubscribeActivityFeed: () => void;
}

const initialShopSlice: ShopSlice = {
  shop: undefined,
  integration: undefined,
  setIntegration: () => {},
  shopId: undefined,
  logoUrl: undefined,
  logoExists: false,
  setShopRole: () => {},
  subscribeShop: () => {},
  shopSubscription: undefined,
  unsubscribeShop: () => {},
  getLogoUrl: async () => {},
  dashboardServiceWriterId:
    localStorage.get("filterByServiceAdvisorId") || "all",
  setDashboardServiceWriterId: () => {},
  dashboardTechnicianId: localStorage.get("filterByTechnicianId") || "all",
  setDashboardTechnicianId: () => {},
  dashboardLayoutId: localStorage.get("dashboardLayoutId") || "0",
  setDashboardLayoutId: () => {},
  refreshTimeInSeconds: 300,
  isSyncing: false,
  lastSyncedTime: 0,
  syncButtonText: "Sync",
  syncError: false,
  timers: undefined,
  subscribeTimers: () => {},
  timersSubscription: undefined,
  employees: [],
  subscribeEmployees: () => {},
  employeesSubscription: undefined,
  unsubscribeEmployees: () => {},
  setCurrentShopId: () => {},
  featureFlags: undefined,
  setFeatureFlags: () => {},
  subscribeSyncDriver: () => {},
  syncDriverSubscription: undefined,
  unsubscribeSyncDriver: () => {},
  syncDriver: undefined,
  syncWithSource: () => {},
  containers: [],
  layouts: [],
  defaultTimer: "",
  cardContents: {},
  statuses: [],
  lastSyncedTimestamp: 0,
  refreshRate: 300,
  cards: [],
  subscribeCards: () => {},
  cardSubscription: undefined,
  unsubscribeCards: () => {},
  shopRole: undefined,
  activityFeed: [],
  subscribeActivityFeed: () => {},
  activityFeedSubscription: undefined,
  unsubscribeActivityFeed: () => {},
};

export const createShopSlice: StateCreator<
  ShopSlice & UserSlice & AuthSlice,
  [],
  [],
  ShopSlice
> = (set, get) => ({
  ...initialShopSlice,
  setCurrentShopId: (id: string | null) => {
    localStorageManager.setItem(KEYS.SHOP_ID, id);
    set({ shopId: id === null ? undefined : id });

    if (id === "unverified" || id === null) {
      get().unsubscribeShop();
      return;
    } else {
      const user = get().user;
      sentry.setUser({ id: user?.id, email: user?.email, shopId: id });
    }
    get().subscribeShop(id);
  },
  setDashboardServiceWriterId: (id) => {
    set({ dashboardServiceWriterId: id });
    const shopId = get().shopId;
    localStorage.set("filterByServiceAdvisorId", id, shopId);
  },
  setDashboardTechnicianId: (id) => {
    set({ dashboardTechnicianId: id });
    const shopId = get().shopId;
    localStorage.set("filterByTechnicianId", id, shopId);
  },
  setDashboardLayoutId: (id) => {
    set({ dashboardLayoutId: id });
    const shopId = get().shopId;
    localStorage.set("dashboardLayoutId", id, shopId);
  },
  subscribeSyncDriver: () => {
    const shopId = get().shopId;
    console.log("IN subscribe Sync Driver", shopId);
    if (!shopId) return;
    const subscription = firestore.subscribeDocument<SyncDriver>(
      `shops/${shopId}/sync/syncdriver`,
      (doc) => {
        set({
          syncDriver: doc,
          lastSyncedTime: firestoreToDateTime(
            doc?.lastSuccessfulSyncAt
          )?.getTime(),
        });
      }
    );
    set({ syncDriverSubscription: subscription });
  },
  unsubscribeSyncDriver: () => {
    const unsubscribe = get().syncDriverSubscription;
    if (unsubscribe) unsubscribe();
    set({ syncDriverSubscription: undefined, syncDriver: undefined });
  },
  setIntegration: () => {
    const shopId = get().shopId;
    if (!shopId) return;
    shop.get(shopId).then((data) => {
      if (!data) return;
      set({
        integration: {
          integration: data.integration ?? "tekmetric",
          apiUrl:
            config.integrations[data.integration ?? "tekmetric"].urls.api[
              data.integrationKey ?? "prod"
            ] ?? "",
          linkUrl:
            config.integrations[data.integration ?? "tekmetric"].urls.links[
              data.integrationKey ?? "prod"
            ] ?? "",
          apiKey:
            config.integrations[data.integration ?? "tekmetric"].urls.apiKeys[
              data.integrationKey ?? "prod"
            ] ?? "",
        },
      });
    });
  },
  setShopRole: (role: EmployeeRole) => {
    const authRole = get().authClaims;
    if (authRole?.superAdmin) {
      set({ shopRole: role });
    }
  },
  subscribeShop: async (id: string) => {
    const userShopRole = await user.getUserShopRole(id);
    if (!userShopRole) {
      // return;
    }

    // User has access;
    console.log(userShopRole);
    set({ shopRole: userShopRole ?? "admin" });
    console.log("Subscribing to shop");

    const subscription = shop.subscribeShop(id, (doc) => {
      set({
        shop: doc,
        lastSyncedTime: doc?.lastSyncedTimestamp ?? 0,
        containers: doc?.containers ?? [],
        layouts: doc?.layouts ?? [],
        defaultTimer: doc?.defaultTimer ?? "",
        cardContents: doc?.cardConfig.cardContents ?? {},
        statuses: doc?.statusesToSync ?? [],
        lastSyncedTimestamp: doc?.lastSyncedTimestamp ?? 0,
        refreshRate: doc?.refreshTimeInSeconds ?? 300,
        logoUrl: doc?.logoUrl ?? get().logoUrl,
      });
    });

    get().setDashboardServiceWriterId(
      localStorage.get("filterByServiceAdvisorId", id) ?? "all"
    );
    get().setDashboardTechnicianId(
      localStorage.get("filterByTechnicianId", id) ?? "all"
    );
    get().setDashboardLayoutId(
      localStorage.get("dashboardLayoutId", id) ?? "0"
    );
    set({ shopSubscription: subscription, shopId: id });
    get().getLogoUrl();
    get().subscribeEmployees();
    get().subscribeTimers();
    get().setFeatureFlags();
    get().subscribeSyncDriver();
    get().subscribeCards();
    get().subscribeActivityFeed();
    get().setIntegration();
  },
  unsubscribeShop: () => {
    const unsubscribe = get().shopSubscription;
    if (unsubscribe) unsubscribe();

    const unsubscribeEmployees = get().employeesSubscription;
    if (unsubscribeEmployees) unsubscribeEmployees();

    const unsubscribeTimers = get().timersSubscription;
    if (unsubscribeTimers) unsubscribeTimers();

    const unsubscribeSyncDriver = get().syncDriverSubscription;
    if (unsubscribeSyncDriver) unsubscribeSyncDriver();

    const unsubscribeCards = get().cardSubscription;
    if (unsubscribeCards) unsubscribeCards();

    const unsubscribeActivityFeed = get().activityFeedSubscription;
    if (unsubscribeActivityFeed) unsubscribeActivityFeed();

    set({ shopSubscription: undefined, shop: undefined, shopId: undefined });
  },
  getLogoUrl: async () => {
    const shop = get().shop;
    const shopId = get().shopId;
    if (shop?.logoUrl) {
      set({ logoUrl: shop?.logoUrl, logoExists: true });
      return;
    }

    const logoRef = ref(storage, `${shopId}/logo`);
    const path = logoRef.fullPath;

    try {
      const checkUrl = await getDownloadURL(ref(storage, path)).then((url) => {
        return url;
      });
      // console.log("Getting Logo");
      set({ logoUrl: checkUrl });

      const img = new Image();
      img.onload = function () {
        set({ logoExists: true });
      };
      img.onerror = function () {
        set({ logoExists: false });
        img.src = checkUrl;
      };
    } catch (error) {
      console.error(error);
      set({ logoExists: false, logoUrl: undefined });
    }
  },
  syncWithSource: (force?: boolean) => {
    const ff = get().featureFlags;
    if (ff && ff["isManualCardOnlyEnabled"]) {
      console.log("manual card creation only. Skipping sync");
      return;
    }
    const integration = get().integration;
    if (!integration) {
      console.log("No integration set");
      return;
    }
    const lastSuccessfulSyncAt = firestoreToDateTime(
      get().syncDriver?.lastSuccessfulSyncAt
    );

    let shouldSync = false;
    if (!lastSuccessfulSyncAt) {
      shouldSync = true;
    } else {
      const now = new Date();
      const diff = now.getTime() - lastSuccessfulSyncAt.getTime();
      const diffInSeconds = Math.floor(diff / 1000);
      if (diffInSeconds > get().refreshRate) {
        shouldSync = true;
      }
    }

    if (force) {
      shouldSync = true;
    }

    if (!shouldSync) {
      console.log("Skipping sync");
      return;
    }

    const shopId = get().shopId;
    if (!shopId) return;
    set({ syncButtonText: "Syncing data...", isSyncing: true });
    httpsCallables
      .syncWithSource({
        shopId,
        integrator: integration.integration,
        apiUrl: integration.apiUrl,
        apiToken: integration.apiKey,
        fullSync: force ?? false,
      })
      .then((res) => console.log(res))
      .catch((error: unknown) => {
        console.error(error);
        set({ syncError: true });
      })
      .finally(() => {
        set({ syncButtonText: "Sync", isSyncing: false });
      });
  },
  subscribeTimers: () => {
    const shopId = get().shopId;
    if (!shopId) return;
    const subscription = firestore.subscribeCollection<Timer>(
      `shops/${shopId}/timers`,
      (docs) => {
        const reducedTimers = docs.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        );
        set({ timers: reducedTimers });
      },
      undefined,
      1000,
      "timeStarted",
      "desc"
    );
    set({ timersSubscription: subscription });
  },
  unsubscribeTimers: () => {
    const unsubscribe = get().timersSubscription;
    if (unsubscribe) unsubscribe();
    set({ timersSubscription: undefined, timers: undefined });
  },
  subscribeEmployees: () => {
    const shopId = get().shopId;
    if (shopId) {
      set({
        employeesSubscription: employee.subscribeAll(shopId, (employees) =>
          set({ employees: employees ?? [] })
        ),
      });
    }
  },
  unsubscribeEmployees: () => {
    const unsubscribe = get().employeesSubscription;
    if (unsubscribe) unsubscribe();
    set({ employeesSubscription: undefined, employees: [] });
  },
  setFeatureFlags: async () => {
    const ff = await featureFlags.list();
    const enabledFf: FeatureFlagEnabled = {};
    const shopId = get().shopId;
    if (!shopId) return;
    if (ff) {
      Object.keys(ff).forEach((key) => {
        if (ff[key].allUsers) {
          enabledFf[key] = ff[key].allUsers;
        } else {
          enabledFf[key] = ff[key].testUsers.includes(shopId);
        }
      });
    }
    set({ featureFlags: enabledFf });
  },
  subscribeCards: () => {
    const shopId = get().shopId;
    if (!shopId) return;
    shop.get(shopId).then((shopData) =>
      shopData
        ? set({
            cardSubscription: card.subscribeAll(
              shopId,
              shopData.statusesToSync,
              (cards) => set({ cards: cards ?? [] })
            ),
          })
        : set({ cardSubscription: undefined })
    );
  },
  unsubscribeCards: () => {
    const unsubscribe = get().cardSubscription;
    if (unsubscribe) unsubscribe();
    set({ cardSubscription: undefined, cards: [] });
  },
  subscribeActivityFeed: () => {
    const shopId = get().shopId;
    if (!shopId) return;
    ActivityFeedRepository.subscribeAll(shopId, (activityFeed) =>
      set({ activityFeed: activityFeed ?? [] })
    );
  },
  unsubscribeActivityFeed: () => {
    const unsubscribe = get().activityFeedSubscription;
    if (unsubscribe) unsubscribe();
    set({ activityFeedSubscription: undefined, activityFeed: [] });
  },
});
