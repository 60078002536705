import { useStore } from "@/stores/useStore";
import shdbLogo from "../../assets/ShdbLogo.png";
import { Button } from "../Base/Button";
import {
  CircleAlertIcon,
  CircleCheckBigIcon,
  ClockIcon,
  Menu as MenuIcon,
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { Feature } from "flagged";
import Tooltip from "@/components/Common/Tooltip";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Base/Select";
import { CardFormDialog } from "../Dashboard/components/Card/CardFormDialog";
import { CreateCardDto, EmployeeRole } from "@/types/v2";
import { card } from "@/models/cards";
import { useMemo } from "react";
import { getServiceAdvisorColor } from "@/lib/employees";
import { useRoles } from "@/hooks/useRoles";

export interface HeaderProps {
  onMenuClick: () => void;
}

export const Header = ({ onMenuClick }: HeaderProps) => {
  const serviceAdvisors = useStore((s) => s.employees);
  const layoutsData = useStore((s) => s.layouts);
  const {
    logoUrl,
    authState,
    shopId,
    dashboardServiceWriterId,
    setDashboardServiceWriterId,
    dashboardTechnicianId,
    setDashboardTechnicianId,
    dashboardLayoutId,
    setDashboardLayoutId,
    lastSyncedTime,
    syncDriver,
    syncWithSource,
    setShopRole,
    shopRole,
  } = useStore();

  const { isViewOnly, isSuperAdmin } = useRoles();
  const location = useLocation();

  const handleServiceWriteChange = (value: string) => {
    setDashboardServiceWriterId(value);
  };

  const handleTechnicianChange = (value: string) => {
    setDashboardTechnicianId(value);
  };

  const handleLayoutSelect = (value: string) => {
    setDashboardLayoutId(value);
  };

  const handleShopRoleChange = (role: EmployeeRole) => {
    setShopRole(role);
  };

  const handleManualOnSubmit = async (values: CreateCardDto) => {
    if (!shopId) return;
    await card.create(shopId, values);
    return;
  };

  const syncButtonText = useMemo(() => {
    if (!syncDriver) {
      return "Sync";
    }
    switch (syncDriver.status) {
      case "complete":
        return "Sync";
      case "error":
        return "Sync";
      case "inprogress":
        return "Syncing";
    }
  }, [syncDriver]);

  return (
    <div className="text-lg flex flex-row justify-between items-center px-2 py-2 shadow-lg">
      <Button
        variant="ghost"
        size="icon"
        onClick={onMenuClick}
        className="m-2"
        disabled={authState !== "authenticated"}
      >
        <MenuIcon />
      </Button>
      <div>
        <img
          src={logoUrl ? logoUrl : shdbLogo}
          alt="logo"
          style={{ height: "60px" }}
        />
      </div>
      {isSuperAdmin && (
        <div className="flex flex-col">
          <p className="text-xs text-center text-primary">Shop Role</p>
          <div>
            <Select
              onValueChange={(value) =>
                handleShopRoleChange(value as EmployeeRole)
              }
              value={shopRole}
            >
              <SelectTrigger className="w-28">
                <SelectValue placeholder="Shop Role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="office">Office</SelectItem>
                <SelectItem value="technician">Technician</SelectItem>
                <SelectItem value="viewOnly">View Only</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      )}
      <div className="flex-grow"></div>
      <div>
        <div className="flex flex-row items-end gap-4">
          {authState === "unauthenticated" && (
            <Link className="px-10" to="">
              Login
            </Link>
          )}
          {authState === "unauthenticated" && (
            <Link className="px-10" to="signup">
              Signup
            </Link>
          )}
          {authState === "authenticated" && shopId && (
            <>
              <Link
                to={
                  location.pathname.includes("activityFeed")
                    ? `/${shopId}`
                    : `/${shopId}/activityFeed`
                }
              >
                <Button variant="secondary">
                  {location.pathname.includes("activityFeed")
                    ? "Dashboard"
                    : "Activity Feed"}
                </Button>
              </Link>
              {!isViewOnly && (
                <Feature name="isManualCardsEnabled">
                  <CardFormDialog onSubmit={handleManualOnSubmit} />
                </Feature>
              )}
              {!isViewOnly && (
                <Feature name="isManualCardOnlyEnabled">
                  {(enabled) =>
                    enabled ? (
                      <CardFormDialog onSubmit={handleManualOnSubmit} />
                    ) : (
                      <div className="flex flex-col">
                        <p className="text-xs text-center">
                          Last Sync:{" "}
                          {new Date(lastSyncedTime).toLocaleTimeString()}
                        </p>
                        <div className="flex flex-row justify-between items-center">
                          <Button
                            className="w-24"
                            variant={"secondary"}
                            disabled={syncDriver?.status === "inprogress"}
                            onClick={() => syncWithSource(true)}
                          >
                            {syncButtonText}
                          </Button>
                          {syncDriver?.status === "error" && (
                            <Tooltip message="Last sync failed!">
                              <CircleAlertIcon className="text-orange-500" />
                            </Tooltip>
                          )}
                          {syncDriver?.status === "inprogress" && (
                            <Tooltip message="Sync in progress">
                              <ClockIcon />
                            </Tooltip>
                          )}
                          {syncDriver?.status === "complete" && (
                            <Tooltip
                              message={`Last sync complete at ${new Date(lastSyncedTime).toLocaleTimeString()}`}
                            >
                              <CircleCheckBigIcon className="text-green" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )
                  }
                </Feature>
              )}

              <Feature name="isServiceAdvisorFilterEnabled">
                <div className="flex flex-col">
                  <p className="text-xs text-center text-primary">
                    Service Advisor
                  </p>
                  <div>
                    <Select
                      onValueChange={(value) => handleServiceWriteChange(value)}
                      value={dashboardServiceWriterId ?? "all"}
                    >
                      <SelectTrigger className="w-48">
                        <SelectValue placeholder="Service Advisor" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">Show All</SelectItem>
                        {serviceAdvisors
                          ?.filter((sa) => !sa.hideInShopHero)
                          .map((serviceAdvisor) => (
                            <SelectItem
                              key={serviceAdvisor.id}
                              value={`${serviceAdvisor.id}`}
                            >
                              {/* Circle color next to the name */}
                              <span
                                className="inline-block w-2.5 h-2.5 rounded-full mr-2"
                                style={{
                                  backgroundColor: getServiceAdvisorColor(
                                    serviceAdvisor.id,
                                    serviceAdvisors || []
                                  ),
                                }}
                              ></span>
                              {serviceAdvisor.firstName}{" "}
                              {serviceAdvisor.lastName}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </Feature>

              <Feature name="isTechnicianFilterEnabled">
                <div className="flex flex-col">
                  <p className="text-xs text-center text-primary">Technician</p>
                  <div>
                    <Select
                      onValueChange={(value) => handleTechnicianChange(value)}
                      value={dashboardTechnicianId ?? "all"}
                    >
                      <SelectTrigger className="w-48">
                        <SelectValue placeholder="Technician" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">Show All</SelectItem>
                        {serviceAdvisors
                          ?.filter((sa) => !sa.hideInShopHero)
                          .map((serviceAdvisor) => (
                            <SelectItem
                              key={serviceAdvisor.id}
                              value={`${serviceAdvisor.id}`}
                            >
                              {serviceAdvisor.firstName}{" "}
                              {serviceAdvisor.lastName}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </Feature>

              <div className="flex flex-col items-center justify-center">
                <p className="text-xs text-center text-primary">Layout</p>

                <Select
                  onValueChange={(value) => handleLayoutSelect(value)}
                  value={dashboardLayoutId}
                >
                  <SelectTrigger className="w-48">
                    <SelectValue
                      placeholder="Service Layout"
                      defaultValue={"0"}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {layoutsData.map((obj, index) => {
                      const name = Object.keys(obj)[0];
                      return (
                        <SelectItem key={name} value={`${index}`}>
                          {name}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
