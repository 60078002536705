import React from "react";
import { SHDBHeader } from "../Header/SHDBHeader";

export const Admin = (props) => {
  return (
    <>
      <SHDBHeader
        logoExists={props.logoExists}
        logoUrl={props.logoUrl}
        shopId={props.shopId}
      />
      <div
        style={{
          padding: "10px 20px",
          backgroundColor: "#202124",
          color: "#ffffff",
        }}
      ></div>
    </>
  );
};
