import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useStore } from "@/stores/useStore";
import { shop as shopActions } from "@/models/shop";
import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";
import { useRoles } from "@/hooks/useRoles";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";

const IntegrationsPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopData = useStore((s) => s.shop);
  const setIntegration = useStore((s) => s.setIntegration);

  const handleToggleDevKey = () => {
    if (!shopData) return;
    if (shopData.integrationKey === "dev") {
      return shopActions
        .update(shopData.shopId, { integrationKey: "prod" })
        .then(() => setIntegration());
    }

    return shopActions
      .update(shopData.shopId, { integrationKey: "dev" })
      .then(() => setIntegration());
  };

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  return (
    <div className="p-5 max-w-screen-md">
      <h1 style={{ fontSize: 24 }}>Integrations</h1>

      {shopData ? (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                height: 10,
                width: 10,
                marginRight: 6,
                marginTop: 8,
                backgroundColor: "green",
              }}
            ></div>
            <div>
              Your current integration with Tekmetric shop {shopData.shopId} is
              ACTIVE
            </div>
          </div>
          <div style={{ fontStyle: "italic", fontSize: 12, marginTop: 20 }}>
            The ability to manage your Tekmetric integration is coming soon!
          </div>
          {isSuperAdmin && (
            <div>
              <EnableDisableSwitch
                label={"Production Key"}
                enabled={
                  shopData?.integrationKey
                    ? shopData.integrationKey === "prod"
                    : true
                }
                onChange={() => handleToggleDevKey()}
              />
            </div>
          )}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default IntegrationsPage;
