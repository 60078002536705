import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { slide as Menu } from "react-burger-menu";
import SupportIcon from "../../assets/support.png";
import PhoneIcon from "../../assets/phone.png";
import LogoutIcon from "../../assets/logout.png";
import GarageIcon from "../../assets/garage.svg";
import CardIcon from "../../assets/card.svg";
import IntegrationIcon from "../../assets/integration.svg";
import LayoutIcon from "../../assets/layout.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import { userEntity } from "../../data/Context/user";
import { currentShopId, setCurrentShopId } from "../../data/Context/shop";
import shdbLogo from "../../assets/ShdbLogo.png";
import { AppVersion } from "@/components/Common/AppVersion";

export const SHDBHeader = (props) => {
  const user = userEntity.get();
  const theActiveShopId = currentShopId.use();
  const auth = getAuth();
  const navigate = useNavigate();

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [activeShopId, setActiveShopId] = useState("");

  useEffect(() => {
    if (theActiveShopId !== null) {
      setActiveShopId(theActiveShopId);
    }
  }, [theActiveShopId]);

  const toggleHambuger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const handleSignOut = () => {
    // console.log("signing out");
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // console.log("sign out successful");
        navigate("/");
        window.location.reload(); // Force a page reload to clear the state of the app
      })
      .catch((error) => {
        // An error happened.
        console.log("sign out error", error);
      });
  };

  var styles = {
    bmBurgerButton: {
      position: "absolute",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#2D67B2",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      left: "0px",
      top: "0px",
    },
    bmMenu: {
      background: "#2D67B2",
      padding: "0",
      fontSize: "1.0em",
      fontWeight: "normal",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#ffffff",
      padding: "0.8em",
      fontSize: "1.3em",
    },
    bmItem: {
      // display: 'inline-block'
    },
    bmOverlay: {
      position: "fixed",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.7)",
    },
  };

  const menuItems = {
    marginBottom: 10,
    flexDirection: "row",
    display: "flex",
  };

  // console.log(props.user);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          color: "#244C91",
          paddingLeft: "100px",
          paddingRight: "100px",
          alignItems: "center",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          {props.logoExists && props.logoUrl?.length > 0 ? (
            <img src={props.logoUrl} alt="logo" style={{ height: "80px" }} />
          ) : (
            <img src={shdbLogo} alt="logo" style={{ height: "80px" }} />
          )}
        </div>
        {(props.shopId === null || props.shopId === undefined) &&
        (user ? user.uid === "" : true) ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link style={{ padding: "20px 40px" }} to="">
              Login
            </Link>
            <Link style={{ padding: "20px 40px" }} to="signup">
              Signup
            </Link>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Menu right={false} width={"280px"} styles={styles}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "#ffffff",
                  width: "100%",
                }}
              >
                <img
                  src={shdbLogo}
                  alt="logo"
                  style={{
                    height: "auto",
                    width: "70%",
                    marginBottom: 15,
                    marginTop: 15,
                    marginLeft: -20,
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: 100,
                  left: 0,
                  paddingLeft: 20,
                  height: "100%",
                  width: "100%",
                }}
              >
                {/* Menu - Top Section */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={menuItems}>
                    {user && user.allowedShops?.length > 1 && (
                      <div
                        style={{
                          marginTop: 20,
                          width: "100%",
                          marginRight: "1em",
                        }}
                      >
                        <p>Active Shop:</p>
                        <select
                          value={activeShopId}
                          style={{
                            color: "#000000",
                            padding: 10,
                            border: "1px solid gray",
                            borderRadius: 10,
                            width: "100%",
                            backgroundColor: "#ffffff",
                          }}
                          onChange={(e) => setCurrentShopId(e.target.value)}
                          name="cardPrimaryField"
                        >
                          {user?.allowedShopsExpanded.map((shop, index) => {
                            return (
                              <option key={index} value={shop.id}>
                                {shop.name !== "" && shop.name !== "undefined"
                                  ? shop.name
                                  : shop.id}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={DashboardIcon}
                      alt="Layout"
                    />
                    <Link style={{ color: "#fff" }} to="../">
                      Dashboard
                    </Link>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      borderBottomWidth: 1,
                      borderBottomColor: "#fff",
                      marginBottom: 10,
                    }}
                  ></div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={GarageIcon}
                      alt="Settings"
                    />
                    <Link style={{ color: "#fff" }} to="../companyInfo">
                      Company Info
                    </Link>
                  </div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={LayoutIcon}
                      alt="Settings"
                    />
                    <Link style={{ color: "#fff" }} to="../admin">
                      Layout Builder
                    </Link>
                  </div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={LayoutIcon}
                      alt="Metrics"
                    />
                    <Link style={{ color: "#fff" }} to="../metrics">
                      Metrics
                    </Link>
                  </div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={CardIcon}
                      alt="Settings"
                    />
                    <Link style={{ color: "#fff" }} to="../cardConfig">
                      Card Configuration
                    </Link>
                  </div>

                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={IntegrationIcon}
                      alt="Settings"
                    />
                    <Link style={{ color: "#fff" }} to="../integrations">
                      Integrations
                    </Link>
                  </div>
                </div>

                {/* Menu - Bottom Section */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 100,
                  }}
                >
                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={SupportIcon}
                      alt="Support"
                    />
                    <a
                      style={{ color: "#fff" }}
                      href="mailto:myhero@shopherodashboard.com"
                    >
                      Support
                    </a>
                  </div>
                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={PhoneIcon}
                      alt="814-208-5088"
                    />
                    <a style={{ color: "#fff" }} href="tel:+18142085088">
                      814-208-5088
                    </a>
                  </div>
                  <div style={menuItems}>
                    <img
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      src={LogoutIcon}
                      alt="Logout"
                    />
                    <div>
                      <button
                        style={{
                          color: "#ffffff",
                          backgroundColor: "transparent",
                          padding: 10,
                          paddingTop: 0,
                          paddingLeft: 0,
                          borderRadius: 10,
                        }}
                        onClick={handleSignOut}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                  <AppVersion />
                </div>
              </div>
            </Menu>
          </div>
        )}
      </div>
      <Outlet />
    </div>
  );
};
