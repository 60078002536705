import { ActivityFeed } from "@/components/ActivityFeed/ActivityFeed";

export const ActivityFeedPage = () => {
  return (
    <div className="p-5 max-w-screen-lg flex flex-col items-center text-justify mx-auto mb-20">
      <h1 className="text-3xl font-bold">Activity Feed</h1>
      <div>
        <ActivityFeed />
      </div>
    </div>
  );
};
