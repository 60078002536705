import { useStore } from "@/stores/useStore";
import { useMemo } from "react";

export const useRoles = () => {
  const shopId = useStore((s) => s.shopId);
  const shopRole = useStore((s) => s.shopRole);
  const authRole = useStore((s) => s.authClaims);

  const isSuperAdmin = useMemo(
    () => shopRole === "superAdmin" || authRole?.superAdmin,
    [shopRole, authRole]
  );
  const isAdmin = useMemo(() => shopRole === "admin", [shopRole]);
  const isOffice = useMemo(() => shopRole === "office", [shopRole]);
  const isTechnician = useMemo(() => shopRole === "technician", [shopRole]);
  const isViewOnly = useMemo(() => shopRole === "viewOnly", [shopRole]);
  const isInitialized = useMemo(
    () => (shopId ? !!shopRole : true) && !!authRole,
    [shopRole, shopId, authRole]
  );

  return {
    isSuperAdmin,
    isAdmin,
    isOffice,
    isTechnician,
    isViewOnly,
    isInitialized,
  };
};
