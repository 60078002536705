import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Error } from "./Error";
import { UploadCloudIcon } from "lucide-react";
import { useFutureCallback } from "@/hooks/useFutureCallback";
import { cn } from "@/lib/utils";
import { Label } from "../Base/Label";

interface FileUploadProps {
  label?: string;
  id?: string;
  handleFileUpload?: (file: File) => void;
  error?: boolean;
  errorMessage?: string;
  text?: string;
}

export const FileUpload = ({
  label,
  id,
  handleFileUpload,
  error,
  errorMessage,
  text,
}: FileUploadProps) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone();

  handleFileUpload = useFutureCallback(handleFileUpload ?? (() => {}));

  useEffect(() => {
    console.log("Uploaded files:", acceptedFiles);

    acceptedFiles.forEach((file) => {
      if (handleFileUpload) {
        handleFileUpload(file);
      }
    });
  }, [acceptedFiles, handleFileUpload]);

  return (
    <div className="flex flex-col gap-y-3">
      {label && <Label id={id}>{label}</Label>}
      <div
        {...getRootProps({
          className: cn(
            "dark:bg-slate-100 bg-slate-400 flex flex-col py-8 text-center rounded-md cursor-pointer transistion-all duration-200 items-center",
            isFocused
              ? "border-accent border-solid border"
              : isDragAccept
                ? "border-green-600 border-4"
                : isDragReject
                  ? "border-destructive border-4"
                  : "border-slate-700 border-dashed border"
          ),
        })}
      >
        <input {...getInputProps({ id: id || "file-upload-input" })} />
        <div>
          <UploadCloudIcon size={64} />
        </div>
        <div>{text}</div>
      </div>
      {error && errorMessage && <Error error={errorMessage} />}
    </div>
  );
};
