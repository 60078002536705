import { useStore } from "@/stores/useStore";
import { useMemo } from "react";
import { TERMS_VERSION } from "@/lib/terms";

export const useTermsAccepted = () => {
  const user = useStore((s) => s.user);
  const termsAccepted = useMemo(
    () =>
      !!(user?.acceptedTermsAt && user.acceptedTermsVersion === TERMS_VERSION),
    [user]
  );

  const termsLoading = useMemo(() => !user, [user]);

  return { termsAccepted, termsLoading };
};
