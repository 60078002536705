import { Step } from "react-joyride";

export const EMPLOYEE_TABLE_STEPS: Step[] = [
  {
    target: "#create_employee",
    content:
      "You create employees here. Employees created in Shop Hero are only in Shop Hero and do not sync back to your integrator",
    disableBeacon: true,
  },
  {
    target: "#edit_employee",
    content: "Use this button to edit an employee",
    disableBeacon: true,
  },
  {
    target: "#delete_employee",
    content:
      "You can delete employees you create. Note that this cannot be undone.",
    disableBeacon: true,
  },
  {
    target: "#employee_name",
    content:
      "This is the employee name. To edit it, update your integrator or use the edit button if this employee is one that you created",
    disableBeacon: true,
  },
  {
    target: "#employee_email",
    content:
      "This is the employee email that they use to log in to your integrator or Shop Hero. Once an account is activated in Shop Hero, this will be the email they use to log in. To edit it, update your integrator or use the edit button if this employee is one that you created",
    disableBeacon: true,
  },
  {
    target: "#employee_visibility",
    content:
      "This is used to determine if the employee should be shown in the technician or service advisor filter dropdowns.",
    disableBeacon: true,
  },
  {
    target: "#employee_account",
    content:
      "This is the account status in Shop Hero. The employee can either not have an account or have an account. In the case of having an account, you can deactivate the account, but you cannot delete it.",
    disableBeacon: true,
  },
  {
    target: "#employee_account_button",
    content: "Use this button to activate or deactivate an employee account",
    disableBeacon: true,
  },
  {
    target: "#employee_role",
    content:
      "There are four roles that you can give to employees. Admin has full access to your shop and settings. Office can update all settings except the integration. Technicians can only view the dashboard and drag and drop cards. View Only can only view the dashboard and change filters, but cannot interact with the cards.",
    disableBeacon: true,
  },
];
