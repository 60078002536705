import { user } from "@/models/user";
import { useQuery } from "react-query";

export const userQuery = (userId: string) => ({
  queryKey: ["users", userId],
  queryFn: () => user.getById(userId),
  refetchOnWindowFocus: false,
});

export const useUserQuery = (userId: string) => {
  return useQuery(userQuery(userId));
};
