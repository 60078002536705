import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { GripVerticalIcon } from "lucide-react";

export interface DragHandleProps {
  dndAttributes: DraggableAttributes;
  dndListeners: SyntheticListenerMap | undefined;
  size?: number;
}

export const DragHandle = ({
  dndAttributes,
  dndListeners,
  size,
}: DragHandleProps) => {
  return (
    <button
      {...dndAttributes}
      {...dndListeners}
      className="cursor-grab hover:opacity-100 opacity-50 p-0 rounded-md"
    >
      <GripVerticalIcon size={size ?? 14} />
    </button>
  );
};
