import { useStore } from "@/stores/useStore";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { Logo } from "@/components/Admin/Logo";
import { EditSaveInput } from "@/components/Common/EditSaveInput";
import { shop } from "@/models/shop";
import { Shop } from "@/types/v1";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";
import { useRoles } from "@/hooks/useRoles";

const CompanyInfoPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopData = useStore((s) => s.shop);
  const shopLogoUrl = useStore((s) => s.logoUrl);

  const handleSave = (key: keyof Shop, newName: string) => {
    if (!shopData?.shopId) return;
    shop.update(shopData?.shopId, { [key]: newName });
  };

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  return (
    <div className="p-5 max-w-screen-md">
      <h1 className="text-2xl">Company Info</h1>
      {shopData ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between items-center">
            Company Name:{" "}
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.name}
              onSave={(value) => handleSave("name", value as string)}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            Company Email:{" "}
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.email}
              onSave={(value) => handleSave("email", value as string)}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            Company Phone:{" "}
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.phone}
              onSave={(value) => handleSave("phone", value as string)}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            Company Website:{" "}
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.website}
              onSave={(value) => handleSave("website", value as string)}
            />
          </div>
          <div>Shop ID: {shopData?.shopId}</div>
          <div>Account Creation Date: {shopData?.createdOn}</div>

          <div>Shop Logo:</div>
          <Logo shopId={shopData.shopId} logoUrl={shopLogoUrl} canBeChanged />
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CompanyInfoPage;
