import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { firestore } from "../services/firestore";
export const firestoreShop = {
  listShopsbyUser: async (userId) => {
    const colRef = collection(db, "shops");
    const q = query(colRef, where("users", "array-contains", userId));
    const querySnapshot = await getDocs(q);
    let shops = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      shops.push({ ...doc.data(), id: doc.id });
    });
    // Get shop if userId is same as Shop ID
    const shopRef = doc(db, "shops", userId);
    const shopdata = await getDoc(shopRef);
    if (shopdata.exists()) {
      shops.push({ ...shopdata.data(), id: shopdata.id });
    }
    return shops;
  },
  listShops: async () => {
    return firestore.listDocuments("shops");
  },
  subscribeShop: async (id, callback) => {
    return firestore.subscribeDocument(`shops/${id}`, callback);
  },
};
