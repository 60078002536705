import { Vehicle } from "@/types/v2";
import { Vehicle as VehicleV1 } from "@/types/v1";

export const vehicle = {
  isV1: (
    vehicle: VehicleV1 | Vehicle
  ): vehicle is VehicleV1 & { schemaVersion?: number } => {
    return (vehicle as Vehicle).schemaVersion === undefined;
  },
  transformV1ToV2: (vehicle: VehicleV1): Vehicle => {
    return {
      schemaVersion: 2,
      id: `${vehicle.id}`,
      make: vehicle.make ?? "",
      model: vehicle.model ?? "",
      year: `${vehicle.year}`,
    };
  },
};
