export const TERMS_VERSION = "1.0";
export const TERMS = `
<p>
          <strong>Effective Date:</strong> September 15, 2024
        </p>
        <br />
        <p>
          These Terms of Service (“Terms”) govern your access to and use of Shop
          Hero Dashboard Inc. ("Company," “we,” “us,” or “our”) and the services
          we provide through our software-as-a-service platform (“Platform” or
          “Service”). By accessing or using our Platform, you (“Customer,”
          “you,” or “your”) agree to comply with and be bound by these Terms. If
          you do not agree to these Terms, you may not use the Platform.
        </p>
        <br />

        <h2>1. Definitions</h2>
        <p>
          <strong>“Agreement”</strong> means these Terms of Service, the Privacy
          Policy, any applicable Service Orders, and other documentation
          provided in connection with your use of the Platform.
        </p>
        <br />
        <p>
          <strong>“Service Order”</strong> refers to a written or electronic
          document specifying the subscription term, fees, services, and other
          details agreed upon between the parties.
        </p>
        <br />
        <p>
          <strong>“Platform”</strong> refers to Shop Hero Dashboard Inc.’s
          software-as-a-service (SaaS) platform, including any related features,
          updates, and services provided to you.
        </p>
        <br />
        <p>
          <strong>“SLA”</strong> refers to any Service Level Agreement (if
          applicable) specifying uptime guarantees, response times, or other
          service expectations.
        </p>
        <br />
        <p>
          <strong>“Force Majeure”</strong> means any event beyond our reasonable
          control, including natural disasters, war, or governmental
          restrictions, that prevents us from providing services.
        </p>
        <br />

        <h2>2. Acceptance of the Terms</h2>
        <p>
          By entering into a Service Order, accessing, or using the Platform,
          you acknowledge that you have read, understood, and agree to be bound
          by these Terms and any other applicable agreements or policies
          incorporated herein by reference. The acceptance of these Terms may
          occur through a clickwrap agreement.
        </p>
        <br />

        <h2>3. Grant of License</h2>
        <p>
          Subject to your compliance with these Terms and payment of all
          applicable fees, Company grants you a limited, non-exclusive,
          non-transferable, revocable right to access and use the Platform
          during the Subscription Term specified in the Service Order. The
          license is non-sublicensable and may not be shared without prior
          written approval from the Company.
        </p>
        <br />

        <h2>4. Access and Use</h2>
        <p>
          You may access and use the Platform solely for your internal business
          operations in accordance with these Terms.
        </p>
        <br />
        <ul>
          <li>
            You are responsible for maintaining the security of your account,
            passwords, and other credentials, and for any activity that occurs
            under your account.
          </li>
          <li>You agree not to:</li>
          <ul>
            <li>(a) resell, sublicense, or distribute the Platform;</li>
            <li>(b) reverse-engineer, decompile, or modify the Platform;</li>
            <li>
              (c) use the Platform to violate any applicable laws, third-party
              rights, or for competitive purposes.
            </li>
          </ul>
        </ul>

        <h2>5. Subscription Term and Renewal</h2>
        <p>
          The initial term of your subscription is as specified in the Service
          Order (“Subscription Term”). Unless otherwise stated, the Subscription
          Term will automatically renew for successive periods unless either
          party provides written notice of non-renewal at least 30 days before
          the end of the current Subscription Term. Company reserves the right
          to terminate or suspend the Service for non-payment or other material
          breaches.
        </p>
        <br />

        <h2>6. Fees and Payment</h2>
        <p>
          You agree to pay all fees specified in the applicable Service Order.
          Fees are payable in USD (or another specified currency) and are
          non-refundable unless otherwise expressly stated in these Terms.
        </p>
        <br />
        <ul>
          <li>
            Late payments will incur interest at a rate of 1.5% per month or the
            highest rate permitted by law, whichever is lower. We will notify
            you of late payments, and you will have a 10-day grace period before
            late fees apply.
          </li>
          <li>
            If your account is past due, we may suspend your access to the
            Platform until payment is made in full.
          </li>
          <li>
            Disputes regarding fees must be raised within 30 days of the charge;
            otherwise, the fee will be deemed accepted.
          </li>
        </ul>

        <h2>7. Customer Data</h2>
        <p>
          <strong>Ownership:</strong> As between you and the Company, you retain
          ownership of all data that you submit to the Platform (“Customer
          Data”).
        </p>
        <br />
        <p>
          <strong>License to Use Data:</strong> You grant Company a worldwide,
          royalty-free, non-exclusive license to use, copy, store, transmit, and
          display Customer Data solely for the purpose of providing and
          improving the Platform and related services. The Company may anonymize
          Customer Data for analytics and performance improvement purposes.
        </p>
        <br />
        <p>
          <strong>Data Privacy:</strong> The handling and processing of Customer
          Data are subject to our Privacy Policy, which is incorporated into
          these Terms by reference. We adhere to industry-standard security
          practices and comply with applicable data protection laws, including
          but not limited to GDPR and CCPA.
        </p>
        <br />
        <p>
          <strong>Data Security:</strong> We implement reasonable technical and
          organizational measures, such as encryption and secure data
          transmission protocols, to protect your Customer Data. However, we do
          not guarantee that unauthorized third parties will not be able to
          breach these measures.
        </p>
        <br />
        <p>
          <strong>Data Retention:</strong> Upon termination or expiration of
          your subscription, we will retain Customer Data for up to 30 days to
          allow for data export unless otherwise required by law.
        </p>
        <br />

        <h2>8. Confidentiality</h2>
        <p>
          Each party agrees to protect the other party’s confidential
          information with the same degree of care that it uses to protect its
          own confidential information, but in no event less than a reasonable
          degree of care.
        </p>
        <br />
        <p>
          Confidential information includes all non-public business or technical
          information disclosed by one party to the other under these Terms.
          Confidential information does not include information that:
        </p>
        <br />
        <ul>
          <li>(a) is already publicly known;</li>
          <li>
            (b) was independently developed without access to the confidential
            information;
          </li>
          <li>(c) was obtained lawfully from a third party.</li>
        </ul>
        <p>
          The obligations of confidentiality will survive the termination of
          this Agreement for a period of two (2) years.
        </p>
        <br />

        <h2>9. Intellectual Property</h2>
        <p>
          Company and its licensors retain all rights, title, and interest in
          and to the Platform, including all underlying software, features, and
          related intellectual property rights.
        </p>
        <br />
        <p>
          You agree that no part of the Platform or its intellectual property
          may be used for competitive purposes. Nothing in these Terms grants
          you any rights in the Platform except as expressly provided.
        </p>
        <br />
        <p>
          <strong>Third-Party IP:</strong> Any third-party software or
          components integrated with the Platform are subject to the terms of
          those third-party licenses.
        </p>
        <br />

        <h2>10. Warranties and Disclaimers</h2>
        <p>
          <strong>Platform Warranties:</strong> Company warrants that the
          Platform will operate substantially in accordance with the applicable
          documentation during the Subscription Term.
        </p>
        <br />
        <p>
          <strong>Disclaimer:</strong> Except as expressly provided herein, the
          Platform is provided “as is” and “as available,” and Company disclaims
          all warranties, whether express, implied, statutory, or otherwise,
          including, but not limited to, implied warranties of merchantability,
          fitness for a particular purpose, and non-infringement.
        </p>
        <br />
        <p>
          Company does not warrant that the Platform will be uninterrupted,
          error-free, or free from unauthorized access by third parties.
        </p>
        <br />

        <h2>11. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, in no event will Company be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including loss of profits, revenue, or data, arising
          out of or in connection with your use of the Platform.
        </p>
        <br />
        <p>
          In no event will Company’s aggregate liability for all claims related
          to the Platform exceed the total fees paid by you in the 12-month
          period preceding the date of the claim.
        </p>
        <br />
        <p>
          This limitation of liability applies regardless of the cause of
          action, whether in contract, tort, or otherwise, and excludes cases of
          gross negligence or intentional misconduct.
        </p>
        <br />

        <h2>12. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Company, its
          affiliates, officers, directors, employees, and agents from and
          against any claims, liabilities, damages, losses, and expenses,
          including reasonable attorney’s fees, arising out of or in connection
          with your:
        </p>
        <br />
        <ul>
          <li>(a) use of the Platform;</li>
          <li>(b) breach of these Terms;</li>
          <li>(c) violation of any applicable law;</li>
        </ul>
        <p>
          This indemnification includes claims of intellectual property
          infringement, third-party claims, and data breaches resulting from
          your negligence.
        </p>
        <br />

        <h2>13. Termination</h2>
        <p>
          Either party may terminate these Terms or a Service Order for material
          breach by providing written notice of such breach to the other party.
          The breaching party has 30 days to cure the breach before termination
          becomes effective.
        </p>
        <br />
        <p>
          Upon termination, all rights granted under these Terms will
          immediately cease, and you must stop using the Platform and delete all
          copies of the Platform in your possession.
        </p>
        <br />
        <p>
          Prepaid fees are non-refundable unless termination is due to a
          material breach by the Company.
        </p>
        <br />
        <p>
          <strong>Data Export:</strong> Upon termination, you will have 30 days
          to export your Customer Data before it is deleted, unless otherwise
          required by law.
        </p>
        <br />

        <h2>14. Governing Law and Dispute Resolution</h2>
        <p>
          These Terms will be governed by and construed in accordance with the
          laws of the State of Delaware, without regard to its conflict of law
          principles.
        </p>
        <br />
        <p>
          Any disputes arising out of or relating to these Terms will be
          resolved through binding arbitration in accordance with the rules of
          the American Arbitration Association, conducted in Wilmington,
          Delaware. The arbitration shall be confidential and any award may be
          entered in any court of competent jurisdiction.
        </p>
        <br />
        <p>
          Class action lawsuits and class-wide arbitrations are not permitted
          under these Terms.
        </p>
        <br />

        <h2>15. Miscellaneous</h2>
        <p>
          <strong>Force Majeure:</strong> Neither party shall be liable for any
          failure or delay in performance under these Terms due to causes beyond
          their reasonable control, including acts of God, war, terrorism,
          government action, or natural disasters.
        </p>
        <br />
        <p>
          <strong>Assignment:</strong> You may not assign or transfer these
          Terms or any rights or obligations hereunder without the prior written
          consent of Company. Company may assign or transfer these Terms without
          restriction.
        </p>
        <br />
        <p>
          <strong>Entire Agreement:</strong> These Terms, along with any Service
          Orders and other applicable agreements, constitute the entire
          agreement between you and Company regarding the use of the Platform.
        </p>
        <br />
        <p>
          <strong>Amendments:</strong> Company may modify these Terms at any
          time by providing notice to you. Continued use of the Platform after
          any modification will constitute acceptance of the revised Terms. You
          will be given 30 days' notice of any material changes.
        </p>
        <br />
        <p>
          <strong>Severability:</strong> If any provision of these Terms is
          found to be invalid or unenforceable, the remaining provisions will
          remain in full force and effect.
        </p>
        <br />

        <h2>16. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <br />
        <br />
        <p>Shop Hero Dashboard Inc.</p>
        <p>1001 State Street - Suite 907</p>
        <p>Erie, PA 16501</p>
        <p>814-208-5020</p>
        <p>contact@shopherodashboard.com</p>
`;
