/**
 * A class for managing local storage keys based on configuration.
 */
class ConfigurableLocalStorage {
  allowedKeys: string[];
  restrictedKeys: string[];
  /**
   * @typedef {Object} ConfigurableLocalStorageConfig - Configuration object.
   * @property {string[]} allowedKeys - List of allowed keys.
   * @property {string[]} restrictedKeys - List of restricted keys.
   */

  /**
   * @typedef {Object} LocalStorageItem - Object representing a local storage item.
   * @property {string} key - The key of the local storage item.
   * @property {*} value - The value of the local storage item.
   */

  /**
   * Creates an instance of ConfigurableLocalStorage.
   * @param {ConfigurableLocalStorageConfig} config - Configuration object.
   */
  constructor(config: { allowedKeys: string[]; restrictedKeys: string[] }) {
    this.allowedKeys = config.allowedKeys || [];
    this.restrictedKeys = config.restrictedKeys || [];
  }

  /**
   * Retrieves the value of a local storage item by key.
   * @param {string} key - The key of the local storage item.
   * @returns {*} - The value of the local storage item.
   */
  getItem(key: string) {
    if (this.isKeyAllowed(key)) {
      return localStorage.getItem(key);
    } else {
      console.error(`Key "${key}" is not allowed.`);
      return null;
    }
  }

  /**
   * Sets the value of a local storage item.
   * @param {string} key - The key of the local storage item.
   * @param {*} value - The value to be set.
   */
  setItem(key: string, value: any) {
    if (this.isKeyAllowed(key)) {
      localStorage.setItem(key, value);
    } else {
      console.error(`Key "${key}" is not allowed.`);
    }
  }

  /**
   * Retrieves all local storage items based on the allowed and restricted keys.
   * @returns {LocalStorageItem[]} - Array of local storage items.
   */
  getAllItems() {
    const items = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== null && this.isKeyAllowed(key)) {
        items.push({ key, value: this.getItem(key) });
      }
    }
    return items;
  }

  /**
   * Checks if a given key is allowed based on the configuration.
   * @param {string} key - The key to be checked.
   * @returns {boolean} - True if the key is allowed, false otherwise.
   */
  isKeyAllowed(key: string) {
    return (
      (this.allowedKeys.length === 0 || this.allowedKeys.includes(key)) &&
      !this.restrictedKeys.includes(key)
    );
  }
}

export const KEYS = {
  SHOP_ID: "shdb.shopId",
};

const localStorageConfig = {
  allowedKeys: Object.keys(KEYS).map(
    (key: string) => KEYS[key as keyof typeof KEYS]
  ),
  restrictedKeys: [],
};

export const localStorageManager = new ConfigurableLocalStorage(
  localStorageConfig
);
