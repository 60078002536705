import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../data/firebase";
import { getAuth, signOut } from "firebase/auth";

export const SuperAdmin = () => {
  //   const functions = getFunctions();
  const createShop = httpsCallable(functions, "createShop");
  const createSuperAdmin = httpsCallable(functions, "createSuperAdmin");
  const [shopId, setShopId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");

  const auth = getAuth();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleCreateSuperAdmin = () => {
    createSuperAdmin().catch((error) => {
      const code = error.code;
      const message = error.message;
      const details = error.details;

      console.error(`Error ${code}: ${message} -- ${details}`);
      alert(`Error ${code}: ${message} -- ${details}`);
    });
  };

  const handleCreateShop = (e) => {
    const payload = {};
    payload.shopId = shopId;
    payload.name = name;
    payload.email = email;
    payload.password = password;
    payload.phone = phone;
    payload.website = website;

    createShop(payload).catch((error) => {
      const code = error.code;
      const message = error.message;
      const details = error.details;

      console.error(`Error ${code}: ${message} -- ${details}`);
      alert(`Error ${code}: ${message} -- ${details}`);
    });
  };

  return (
    <div>
      <div sx={{ display: "flex", flexDirection: "column" }}>
        <label htmlFor="shopId">Shop Id</label>
        <input
          type="text"
          id="shopId"
          name="shopId"
          value={shopId}
          onChange={(e) => setShopId(e.target.value)}
          sx={{
            outlineStyle: "solid",
            outlineWidth: 2,
            outlineColor: "#000",
          }}
        />
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="name">Email</label>
        <input
          type="text"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="name">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="name">Phone</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <label htmlFor="name">Website</label>
        <input
          type="text"
          id="website"
          name="website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        <button type="submit" onClick={handleCreateShop}>
          Submit
        </button>
      </div>
      <div>
        {/* <button type="submit" onClick={handleCreateSuperAdmin}>
          Create superAdmin
        </button> */}
        <button type="submit" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    </div>
  );
};
