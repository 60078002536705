import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "@/stores/useStore.ts";
import { KEYS, localStorageManager } from "@/services/localStorage.ts";
import { DashboardPage } from "@/pages";

export const ShopLayoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const allowedShops = useStore((s) => s.allowedShops);
  const setCurrentShopId = useStore((s) => s.setCurrentShopId);
  const { shopId } = useParams();

  useEffect(() => {
    console.log("Location", location);
    const allowedShopIds = allowedShops.map((shop) => shop.shopId);
    if (shopId) {
      if (allowedShopIds.includes(shopId)) {
        setCurrentShopId(shopId);
        if (!location.pathname.includes(shopId)) {
          navigate(`/${shopId}`);
        }
        return;
      }
    }
    if (!shopId) {
      const lastShopId = localStorageManager.getItem(KEYS.SHOP_ID);
      if (lastShopId && allowedShopIds.includes(lastShopId)) {
        setCurrentShopId(lastShopId);
        if (!location.pathname.includes(lastShopId)) {
          navigate(`/${lastShopId}`);
        }
        return;
      }
    }
  }, [allowedShops, setCurrentShopId]);

  if (!shopId) {
    return <DashboardPage />;
  }

  return <Outlet />;
};
