import { clsx, type ClassValue } from "clsx";
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge";
import { parse } from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function arrayUnion<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value), value];
}

export function arrayRemove<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value)];
}

const getBrightness = (color: string) => {
  const hexColor = color.replace(/^#/, "");
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  return (r * 299 + g * 587 + b * 114) / 1000;
};

// Determine the text color based on the background color's brightness
export const getTextColor = (color: string | "transparent") => {
  const brightness = getBrightness(color);
  if (isNaN(brightness)) return undefined;
  return getBrightness(color) > 128 ? "black" : "white";
};

export function formatArrayAsString(arr: string[]) {
  const arrToFormat = [...arr];
  if (arrToFormat.length === 1) {
    return arrToFormat[0];
  } else if (arrToFormat.length === 2) {
    return arrToFormat.join(" and ");
  } else {
    const last = arrToFormat.pop();
    return `${arrToFormat.join(", ")}, and ${last}`;
  }
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function firestoreToDateTime(
  firestoreTime: Timestamp | Date | undefined | null | string
) {
  if (!firestoreTime) {
    return undefined;
  }
  if (typeof firestoreTime === "string") {
    return parse(firestoreTime, "yyyy-MM-dd", new Date());
  }
  if (firestoreTime instanceof Date) return firestoreTime;
  return firestoreTime?.toDate();
}

export function isPast(
  dateToCompare: string | Timestamp | Date | null | undefined
) {
  if (!dateToCompare) return false;

  if (typeof dateToCompare === "string") {
    return new Date(dateToCompare) <= new Date();
  }

  const convertedDate = firestoreToDateTime(dateToCompare);
  if (!convertedDate) return false;
  return convertedDate <= new Date();
}

export function formatDateToMMDDYYWithTime(dateString: null | string) {
  if (dateString === null) {
    return null;
  }

  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Month is zero-based, so +1
  const day = date.getDate();
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  let period = "a";
  if (hours >= 12) {
    period = "p";
    if (hours > 12) hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedTime = `${hours}:${minutes}${period}`;

  return `${month}-${day}-${year} @ ${formattedTime}`;
}

export function getInitials(name: string | undefined): string {
  if (!name) return "";
  return name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
}

const roundAsString = (
  value: number,
  decimalPlaces: number,
  keepDecimals = true
): string => {
  const roundedValue = value.toFixed(decimalPlaces);
  return keepDecimals && value % 1 !== 0
    ? roundedValue
    : parseFloat(roundedValue).toString();
};
export const convertMinutesToHours = (
  minutes: string | number,
  keepDecimals = true
): string => {
  if (typeof minutes === "number") {
    return roundAsString(minutes / 60, 2, keepDecimals);
  }
  return roundAsString(parseFloat(minutes) / 60, 2, keepDecimals);
};
export const convertHoursToMinutes = (
  hours: string | number,
  keepDecimals = true
): string => {
  if (typeof hours === "number") {
    return roundAsString(hours * 60, 2, keepDecimals);
  }
  return roundAsString(parseFloat(hours) * 60, 2, keepDecimals);
};
