import { config } from "@/config";
import * as Sentry from "@sentry/react";

export const sentry = {
  init: () =>
    config.mode === "emulator"
      ? null
      : Sentry.init({
          dsn: config.sentryDsn,
          environment: config.mode,
          release: `${config.version}-${config.commitHash}`,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
          ],
          // Performance Monitoring
          tracesSampleRate: config.mode === "prod" ? 0.3 : 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/shopherodashboard\.web\.app/,
            /^https:\/\/shophero-dashboard-staging\.web\.app/,
            /^https:\/\/dev-shdb\.web\.app/,
          ],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }),
  setUser: ({
    email,
    id,
    shopId,
  }: {
    email?: string;
    id?: string;
    shopId?: string;
  }) => {
    if (!email || !id) {
      Sentry.setUser(null);
    }
    Sentry.setUser({ email, id, shopId: shopId ?? "" });
  },
};

export const captureException = Sentry.captureException;
