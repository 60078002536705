import { useState } from "react";
import { Button } from "../Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Base/Dialog";
import { Input } from "../Base/Input";
import { container } from "@/models/container";

interface RenameContainerDialogProps {
  containerId: string;
  containerName: string;
  onClose: () => void;
}
export const RenameContainerDialog = ({
  containerId,
  containerName,
  onClose,
}: RenameContainerDialogProps) => {
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState(containerName);

  const handleSave = () => {
    container.updateContainerName(containerId, newName);
    setOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>Rename Container</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Container Name</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div>Please provide a new name in the box below.</div>
          <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
          <div className="text-xs">
            Note that container name changes are global and are effective
            immediately. If you want a custom container name for this layout
            only, create a new container.
          </div>
        </div>
        <DialogFooter>
          <Button variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
