import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import { TERMS } from "@/lib/terms";
import { useStore } from "@/stores/useStore";
import { user as userActions } from "@/models/user";

interface TermsDialogProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
}
export const TermsDialog = ({ open, setOpen }: TermsDialogProps) => {
  const user = useStore((s) => s.user);
  const logout = useStore((s) => s.logout);
  const handleAgree = async () => {
    if (!user) return;
    await userActions.agreeToTerms(user.id);
  };

  const handleDisagree = () => {
    logout();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>Terms of Service</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2 max-h-[50vh] overflow-auto">
          <div dangerouslySetInnerHTML={{ __html: TERMS }} />
        </div>
        <DialogFooter>
          <div className="mt-5">
            <p>
              By clicking &quot;Agree,&quot; you acknowledge that you have read
              and understood these Terms of Service and agree to be bound by
              them.
            </p>
            <p>
              You will not be able to use the platform until you agree to the
              Terms of Service
            </p>
            <Button
              className="mt-5 mr-5"
              variant={"accent"}
              onClick={handleAgree}
            >
              Agree
            </Button>
            <Button
              className="mt-5"
              variant={"secondary"}
              onClick={handleDisagree}
            >
              Disagree
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
