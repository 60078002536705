import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import {
  EmployeeForm,
  EmployeeFormProps,
} from "@/components/Employees/Employee.form";
import { CreateEmployeeDto } from "@/types/v2";
import { useState } from "react";
import { Spinner } from "@/components/Common/Spinner";
import { employee as employeeActions } from "@/models/employee";

interface EmployeeDialogProps
  extends Pick<
    EmployeeFormProps,
    "initialData" | "submitTitle" | "cancelTitle"
  > {
  shopId: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
  title?: string;
  description?: string;
}
export const EmployeeDialog = ({
  shopId,
  open,
  setOpen,
  title,
  description,
  ...props
}: EmployeeDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values: CreateEmployeeDto) => {
    setIsSubmitting(true);
    if (props.initialData) {
      await employeeActions.update(shopId, props.initialData.id, {
        ...values,
        schemaVersion: 2,
      });
    } else {
      await employeeActions.create(shopId, values);
    }
    setOpen(false);
    setIsSubmitting(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>{title ?? "Add Team Member"}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {description ??
            "Create a team member to manage and show in ShopHero."}
        </DialogDescription>
        {isSubmitting ? (
          <Spinner size="lg" />
        ) : (
          <EmployeeForm
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            {...props}
          />
        )}

        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
