import { QueryDocumentSnapshot } from "firebase/firestore";

interface Transformer<V1, V2> {
  isV1: (data: V1 | V2) => data is V1;
  transformV1ToV2: (data: V1) => V2;
}

export const createConverter = <V1, V2>(transformer: Transformer<V1, V2>) => {
  return {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    toFirestore(data: any) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<V2 | V1>): V2 {
      const data = snapshot.data();
      if (transformer.isV1(data)) {
        return transformer.transformV1ToV2(data);
      }
      return data;
    },
  };
};
