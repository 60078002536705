import { useStore } from "@/stores/useStore";

const VerifyPage = () => {
  const sendVerificationEmail = useStore((s) => s.sendVerificationEmail);
  const handleVerify = () => {
    sendVerificationEmail();
  };
  return (
    <>
      <div>
        You must verify ownership of the email address used during account
        creation. Please click the button below and follow the steps in the
        email.
      </div>
      <button
        style={{
          marginLeft: 8,
          width: 180,
          padding: 8,
          backgroundColor: "#244C91",
          color: "#ffffff",
          borderRadius: 10,
        }}
        onClick={handleVerify}
      >
        Verify Email Address
      </button>
    </>
  );
};

export default VerifyPage;
