import { config } from "@/config";
import { capitalize } from "@/lib/utils";

export const AppVersion = () => {
  return (
    <div className="text-xs">
      {`${config.mode !== "prod" ? `Environment: ${capitalize(config.mode)} | ` : ""}v${config.version}-${config.build}-${config.commitHash}`}
    </div>
  );
};
