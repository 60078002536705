import { useState } from "react";
import { ShopIdInfo } from "../../components/Dialogs/ShopIdInfo";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../data/firebase";
import { ErrorDialog } from "../../components/Dialogs/ErrorDialog";
import { SuccessDialog } from "../../components/Dialogs/SuccessDialog";
import shdbLogo from "../../assets/ShdbLogo.png";

const SignupPage = () => {
  const [shopId, setShopId] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const checkIfTekmetricAuthorized = httpsCallable(
      functions,
      "checkIfTekmetricAuthorized"
    );
    const payload = {};
    payload.uid = shopId;
    payload.email = email;
    payload.displayName = name;
    payload.phone = phone;
    payload.website = website;
    payload.password = password;
    checkIfTekmetricAuthorized(payload)
      .then((result) => {
        const data = result.data;
        if (data.success) {
          setSuccessDialogOpen(true);
          setDialogMessage(data.success);
        } else {
          setErrorDialogOpen(true);
          setDialogMessage(data.error);
        }
        // console.log("received data from checkIfTekmetricAuthorized:", data);
      })
      .catch((error) => {
        console.error("Error checking if authorized in Tekmetric!:", error);
      });
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 border-white border-2 rounded-xl p-8 bg-secondary/10">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={shdbLogo}
              alt="Shop Hero Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-foreground">
              Create your account
            </h2>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="name" className="sr-only">
                  Shop Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Shop Name"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="shopId" className="sr-only">
                  Shop Id
                </label>
                <input
                  id="shopId"
                  name="shopId"
                  type="text"
                  value={shopId}
                  onChange={(e) => setShopId(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Shop Id"
                />
              </div>
              <div>
                <label htmlFor="website" className="sr-only">
                  Website
                </label>
                <input
                  id="website"
                  name="website"
                  type="text"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Website"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Phone"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
                  placeholder="Password"
                />
              </div>
              <div className="flex flex-row width w-full  place-content-end">
                <button
                  type="button"
                  onClick={handleClick}
                  className="flex px-3 py-0.5 mt-2 rounded-full text-sm font-medium bg-dkBlue text-white place-self-end"
                >
                  Need help finding your shop Id?
                </button>
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:text-black bg-dkGray hover:bg-ltGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
      <ShopIdInfo open={open} setOpen={setOpen} />
      <ErrorDialog
        errorDialogOpen={errorDialogOpen}
        setErrorDialogOpen={setErrorDialogOpen}
        dialogMessage={dialogMessage}
      />
      <SuccessDialog
        successDialogOpen={successDialogOpen}
        setSuccessDialogOpen={setSuccessDialogOpen}
        dialogMessage={dialogMessage}
      />
    </>
  );
};

export default SignupPage;
