import { AlertCircleIcon } from "lucide-react";

interface ErrorProps {
  error: string;
}
export const Error = ({ error }: ErrorProps) => {
  return (
    <div className="text-base italic text-destructive flex flex-row">
      <div>
        <AlertCircleIcon className="w-6 h-6" />
      </div>
      <div className="ml-2">{error}</div>
    </div>
  );
};
