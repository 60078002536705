import { storageActions } from "@/services/storage";
import { shop } from "./shop";
import { getStorage, ref, deleteObject } from "firebase/storage";

const storage = getStorage();

export const logo = {
  upload: async (shopId: string, file: File) => {
    const logoUrl = await storageActions.upload(file, `${shopId}/logo`);
    return shop.update(shopId, { logoUrl: logoUrl });
  },
  delete: async (shopId: string) => {
    console.log("Deleting logo for shop", shopId);

    // Step 1: Delete the file from storage
    const logoRef = ref(storage, `${shopId}/logo`);
    deleteObject(logoRef)
      .then(() => {
        console.log("File deleted successfully");
      })
      .catch((error) => {
        console.log("Uh-oh, an error occurred!", error);
      });

    // Step 2: Remove the logoUrl field from Firestore
    return shop.update(shopId, { logoUrl: "" });
  },
};
