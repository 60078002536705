import { authActions } from "@/services/auth";
import { useStore } from "@/stores/useStore";
import { useEffect, useState } from "react";

export const useIsSuperAdmin = () => {
  const authUser = useStore((s) => s.authUser);
  const [initialized, setInitialized] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (authUser) {
      authActions.getUserClaimRole().then((res) => {
        console.log(res);
        if (res) {
          setInitialized(true);
          setIsSuperAdmin(res === "superAdmin");
        }
      });
    }
  }, [authUser]);
  return [initialized, isSuperAdmin];
};
