import { entity } from "simpler-state";
import { auth } from "../firebase";
import { firestoreUser } from "../firestoreUser";
import { firestoreShop } from "../firestoreShop";
import { pushSubscriber } from "./subscribers";

const initialState = { uid: "", role: "", allowedShops: [] };

export const userEntity = entity(initialState);

const linkedShops = async (role, uid) => {
  // Attach shops that user has access to
  let allowedShops = [];
  if (role === "superAdmin") {
    allowedShops = await firestoreShop.listShops();
  } else {
    allowedShops = await firestoreShop.listShopsbyUser(uid);
  }
  return allowedShops.filter((shop) => !shop.disabled);
};

export const subscribeUser = async () => {
  const authUser = auth.currentUser;
  // console.log("Setting user to NULL");
  if (!authUser) {
    userEntity.init();
    return;
  }

  // Check if user in DB, if not, create
  const dbUser = await firestoreUser.getById(authUser.uid);

  // console.log(dbUser);
  const claims = await authUser.getIdTokenResult();
  const role = claims.claims?.role;
  // console.log("claims", claims.claims?.role);

  if (!dbUser) {
    await firestoreUser.create({
      id: authUser.uid,
      uid: authUser.uid,
      displayName: authUser.displayName,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      creationTime: authUser.metadata.creationTime,
      lastLoginTime: new Date(),
      role: role ?? "user",
    });
  } else {
    await firestoreUser.update({
      id: authUser.uid,
      uid: authUser.uid,
      lastLoginTime: new Date(),
      role: role ?? "user",
    });
  }

  const subscriber = firestoreUser.subscribe(authUser.uid, async (user) => {
    const allowedShops = await linkedShops(role, user.id);
    // console.log("allowedShops", allowedShops);
    userEntity.set({
      ...user,
      allowedShops: allowedShops.map((shop) => shop.id),
      allowedShopsExpanded: allowedShops.map((shop) => ({
        id: shop.id,
        name: shop.name,
      })),
    });
  });

  pushSubscriber(subscriber, "currentUser");
};

export const setUser = async () => {
  const authUser = auth.currentUser;
  // console.log("Setting user");
  if (!authUser) {
    userEntity.init();
    return;
  }

  // Check if user in DB, if not, create
  const dbUser = await firestoreUser.getById(authUser.uid);

  // console.log(dbUser);
  const claims = await authUser.getIdTokenResult();
  const role = claims.claims?.role;
  // console.log("claims", claims.claims?.role);

  if (!dbUser) {
    await firestoreUser.create({
      id: authUser.uid,
      uid: authUser.uid,
      displayName: authUser.displayName,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      creationTime: authUser.metadata.creationTime,
      lastLoginTime: new Date(),
      role: role,
    });
  } else {
    await firestoreUser.update({
      id: authUser.uid,
      uid: authUser.uid,
      lastLoginTime: new Date(),
      role: role,
    });
  }

  const allowedShops = await linkedShops(role, authUser.uid);

  userEntity.set({
    id: authUser.uid,
    uid: authUser.uid,
    displayName: authUser.displayName,
    email: authUser.email,
    emailVerified: authUser.emailVerified,
    creationTime: authUser.metadata.creationTime,
    lastLoginTime: new Date(),
    role: role,
    allowedShops: allowedShops.map((shop) => shop.id),
  });
};

export const initUser = () => {
  userEntity.init();
};
