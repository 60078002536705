import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const updateLayoutsArray = async (payload) => {
  const shopRef = doc(db, "shops", payload.shopId);

  const returnValue = await updateDoc(
    shopRef,
    { layouts: payload.layouts },
    { merge: true }
  )
    .then(() => {
      return "success";
    })
    .catch((err) => {
      console.error("addLayoutToLayouts error", err);
      return err;
    });
  return returnValue;
};
