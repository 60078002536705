import { MoreVerticalIcon } from "lucide-react";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { CardRemoveDialog } from "./CardRemoveDialog";
import { removeCard } from "@/lib/cards";
import { useStore } from "@/stores/useStore";
import { PatchCardDto } from "@/types/v2";
import { card } from "@/models/cards";
import { CardFormDialog } from "./CardFormDialog";

interface CardMenuProps {
  cardId: string;
  cardData: PatchCardDto;
  disabled?: boolean;
}
export const CardMenu = ({ cardId, cardData, disabled }: CardMenuProps) => {
  const shopId = useStore((s) => s.shopId);
  const [open, setOpen] = useState(false);

  if (!shopId) return null;

  const handleRemoveCard = async () => {
    await removeCard(shopId, cardId);
    setOpen(false);
  };

  const handleEditCard = async (values: PatchCardDto) => {
    await card.update(shopId, cardId, {
      ...values,
      _isSoftMigrated: cardData._isSoftMigrated,
    });
    setOpen(false);
    return;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        disabled={disabled}
        className="absolute right-0 top-0 cursor-pointer z-5 p-1 rounded-full text-accent hover:bg-white/50 disabled:cursor-not-allowed"
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MoreVerticalIcon size={14} />
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-2 p-2"
      >
        <CardRemoveDialog onRemove={handleRemoveCard} />

        <CardFormDialog
          title="Edit"
          buttonTitle="Edit Card"
          description="Edit card. Card fields are determined by the card configuration."
          cardData={cardData}
          onSubmit={handleEditCard}
        />
      </PopoverContent>
    </Popover>
  );
};
