import { useEffect, useMemo, useState } from "react";
import { useStore } from "@/stores/useStore";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import {
  DEFAULT_CARD_LABEL_WRAP,
  DEFAULT_CARD_TIMER_UNITS,
  DEFAULT_TIMER_VALUE,
  TEKMETRIC_STATUSES,
} from "@/lib/const";
import { shop } from "@/models/shop";
import { CheckboxInput } from "@/components/Base/CheckboxInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { Input } from "@/components/Base/Input";
import { Button } from "@/components/Base/Button";
import { CheckedState } from "@radix-ui/react-checkbox";
import { CARD_FIELD_OPTIONS, CARD_FIELDS, Shop } from "@/types/v2";
import { showErrorToast, showSuccessToast } from "@/lib/toast";
import {
  capitalize,
  convertHoursToMinutes,
  convertMinutesToHours,
} from "@/lib/utils";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";
import { useRoles } from "@/hooks/useRoles";

const CardConfigPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopId = useStore((s) => s.shopId);
  const shopInfo = useStore((s) => s.shop);

  const cardContentsObj = useMemo(
    () => shopInfo?.cardConfig.cardContents,
    [shopInfo]
  );
  const cardLabelWrap = useMemo(
    () => shopInfo?.cardLabelWrap ?? DEFAULT_CARD_LABEL_WRAP,
    [shopInfo]
  );
  const timerUnits = useMemo(
    () => shopInfo?.cardConfig?.timerUnits ?? DEFAULT_CARD_TIMER_UNITS,
    [shopInfo]
  );

  const [defaultTimerValue, setDefaultTimerValue] =
    useState<string>(DEFAULT_TIMER_VALUE);

  useEffect(() => {
    if (!shopInfo) return;
    setDefaultTimerValue(shopInfo.defaultTimer ?? DEFAULT_TIMER_VALUE);
  }, [shopInfo]);

  console.log(shopInfo);

  const handleCardContentsChange = (fieldValue: string, fieldName: string) => {
    if (!shopId || !cardContentsObj) return;

    shop
      .updateCardContent(shopId, fieldName, fieldValue, cardContentsObj)
      .then(() =>
        showSuccessToast(
          "Card Contents Updated",
          "The card contents have been updated. Return to the dashboard to view."
        )
      )
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .catch((err: any) => {
        console.error("An error occurred updating statuses to fetch: ", err);
        showErrorToast(
          "Card Contents Failed to Update",
          `An error occurred updating card contents: ${err}`
        );
      });
  };

  const handleUpdateDefaultTimerValue = (value: string) => {
    if (timerUnits === "minutes") {
      return setDefaultTimerValue(value);
    }
    return setDefaultTimerValue(convertHoursToMinutes(value));
  };

  const handleStatusClick = (statusId: string) => {
    if (!shopInfo || !shopId) return;
    let statusesToCollect = shopInfo.statusesToSync;
    if (statusesToCollect.includes(statusId)) {
      statusesToCollect = statusesToCollect.filter((s) => s !== statusId);
    } else {
      statusesToCollect.push(statusId);
    }

    shop
      .setStatuses(shopId, statusesToCollect)
      .then(() => {
        showSuccessToast(
          "Statuses Updated",
          "The statuses to sync have been updated. Return to the dashboard to view. Note that it may take time for the updated sync process to complete."
        );
      })
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .catch((err: any) => {
        console.error("An error occurred updating statuses to fetch: ", err);

        showErrorToast(
          "Status Update Failed",
          `An error occurred updating statuses to fetch: ${err}`
        );
      });
  };

  const handleLabelWrapClick = (newSelection: CheckedState) => {
    if (!shopId || !cardLabelWrap) return;

    shop.updateCardLabelWrap(shopId, newSelection ? "yes" : "no").then(() => {
      showSuccessToast(
        "Card Label Wrap Updated",
        "Return to the dashboard to see the updated card label wrap."
      );
    });
  };

  const updateDefaultTimerValue = () => {
    if (!shopId || !defaultTimerValue) return;
    shop.updateDefaultTimerValue(shopId, defaultTimerValue).then(() => {
      showSuccessToast(
        "Default Timer Updated",
        "Return to the dashboard to see the updated default timer."
      );
    });
  };

  const handleTimerUnitsClicked = (
    timerUnits: Shop["cardConfig"]["timerUnits"]
  ) => {
    if (!shopId) return;
    shop.updateTimerUnits(shopId, timerUnits).then(() => {
      showSuccessToast(
        `Timer Units Set to ${timerUnits ? capitalize(timerUnits) : "Unknown"}`,
        "Return to the dashboard to see the updated default timer units."
      );
    });
  };

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  return (
    <div className="p-5">
      <h1 className="text-2xl">Card Configuration</h1>
      {shopInfo ? (
        <div className="flex flex-row gap-8">
          <div className="border border-primary-foreground p-5 rounded-xl m-2.5 flex flex-col">
            <div className="flex flex-col gap-4">
              <h2 className="text-xl pb-5">Statuses to import:</h2>
              {TEKMETRIC_STATUSES.map((status, index) => (
                <div key={`${status.value}-${status.label}-${index}`}>
                  <CheckboxInput
                    value={status.value}
                    aria-label={status.label}
                    label={status.label}
                    checked={shopInfo?.statusesToSync.includes(
                      `${status.value}`
                    )}
                    onClick={() => handleStatusClick(`${status.value}`)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="border border-primary-foreground p-5 rounded-xl m-2.5 flex flex-col gap-4">
            <h2 className="text-2xl">Timers</h2>
            <div>
              <h3 className="text-xl">Default Timer Value:</h3>
              <div className="pb-2.5">
                in {timerUnits ?? DEFAULT_CARD_TIMER_UNITS}
              </div>
              <div className="flex flex-row gap-4">
                <Input
                  type="number"
                  placeholder="15"
                  value={
                    timerUnits === "minutes"
                      ? parseFloat(defaultTimerValue)
                      : parseFloat(convertMinutesToHours(defaultTimerValue))
                  }
                  onChange={(e) =>
                    handleUpdateDefaultTimerValue(e.target.value)
                  }
                  className="text-primary"
                />
                <Button
                  type="submit"
                  onClick={updateDefaultTimerValue}
                  variant={"accent"}
                >
                  Update
                </Button>
              </div>
            </div>
            <div>
              <h3 className="text-xl pb-2.5">Timer Units:</h3>
              <div className="flex flex-col gap-4">
                <CheckboxInput
                  aria-label={"Timer in Minutes"}
                  label={"Timer in Minutes"}
                  checked={
                    timerUnits === undefined ||
                    timerUnits === null ||
                    timerUnits === "minutes"
                  }
                  onCheckedChange={(checked) =>
                    handleTimerUnitsClicked(checked ? "minutes" : "hours")
                  }
                />
              </div>
            </div>
          </div>

          <div className="border border-primary-foreground p-5 rounded-xl m-2.5 flex flex-col">
            <div>
              <h2 className="text-xl">Wrap Text on Card:</h2>
              <div className="flex flex-col gap-4">
                <CheckboxInput
                  value={cardLabelWrap}
                  aria-label={"Wrap Card Labels"}
                  label={"Wrap Card Labels"}
                  checked={cardLabelWrap === "yes"}
                  onCheckedChange={(checked) => handleLabelWrapClick(checked)}
                />
              </div>
            </div>
          </div>

          <div className="border border-primary-foreground p-5 rounded-xl m-2.5 flex flex-col">
            <div>
              <h2 className="text-xl">Card Layout</h2>

              <div className="flex flex-col gap-4">
                {CARD_FIELDS.map((field, index) => (
                  <div key={`${field.key}-${index}`}>
                    <p>{`${field.label} Value:`}</p>
                    <Select
                      onValueChange={(value) =>
                        handleCardContentsChange(value, field.key)
                      }
                      value={
                        cardContentsObj
                          ? cardContentsObj[
                              field.key as keyof typeof cardContentsObj
                            ]
                          : ""
                      }
                    >
                      <SelectTrigger className="w-64 text-primary">
                        <SelectValue placeholder={field.label} />
                      </SelectTrigger>
                      <SelectContent>
                        {CARD_FIELD_OPTIONS.map((name) => (
                          <SelectItem key={name} value={`${name}`}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CardConfigPage;
