import { entity } from "simpler-state";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { currentShopId } from "./shop";

const initialState = async () => {
  const user = auth.currentUser?.uid;
  // console.log(user);
  const docRef = doc(db, "config", "featureFlags");
  const document = await getDoc(docRef);
  const ff = document.data();
  const enabledFf = {};
  if (ff) {
    Object.keys(ff).forEach((key) => {
      if (user && !ff[key].allUsers) {
        enabledFf[key] = ff[key].testUsers.includes(user);
      } else {
        enabledFf[key] = ff[key].allUsers;
      }
    });
  }
  return enabledFf;
};

export const featureFlags = entity({});

export const setUserFeatureFlags = async (userId) => {
  // console.log(`Updating feature flags for user ${userId}`);
  const docRef = doc(db, "config", "featureFlags");
  const document = await getDoc(docRef);
  const ff = document.data();
  // console.log(ff);
  const enabledFf = {};
  if (ff) {
    Object.keys(ff).forEach((key) => {
      if (ff[key].allUsers) {
        enabledFf[key] = ff[key].allUsers;
      } else {
        enabledFf[key] = ff[key].testUsers.includes(userId);
      }
    });
  }
  featureFlags.set(enabledFf);
};
