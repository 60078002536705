import { create } from "zustand";
import { ShopSlice, createShopSlice } from "./shop.slice";
import { AuthSlice, createAuthSlice } from "./auth.slice";
import { UserSlice, createUserSlice } from "./user.slice";

const useStore = create<ShopSlice & AuthSlice & UserSlice>()((...a) => ({
  ...createShopSlice(...a),
  ...createAuthSlice(...a),
  ...createUserSlice(...a),
}));

const store = useStore;
export { useStore, store };
