import { Customer } from "@/types/v2";
import { Customer as CustomerV1 } from "@/types/v1";

export const customer = {
  isV1: (
    customer: CustomerV1 | Customer
  ): customer is CustomerV1 & { schemaVersion?: number } => {
    return (customer as Customer).schemaVersion === undefined;
  },
  transformV1ToV2: (customer: CustomerV1): Customer => {
    return {
      schemaVersion: 2,
      id: `${customer.id}`,
      firstName:
        customer?.customerType?.code === "BUSINESS"
          ? ""
          : (customer?.firstName ?? ""),
      lastName:
        customer?.customerType?.code === "BUSINESS"
          ? (customer?.firstName ?? "")
          : (customer?.lastName ?? ""),
      contactFirstName: customer.contactFirstName ?? "",
      contactLastName: customer.contactFirstName ?? "",
      name:
        customer?.customerType?.code === "BUSINESS"
          ? (customer?.firstName ?? "")
          : `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`,
    };
  },
};
