import { firestore } from "@/services/firestore";
import { Role, User } from "@/types/v1";
import { shop } from "./shop";
import { TERMS_VERSION } from "@/lib/terms";
import { httpsCallables } from "@/models/httpsCallables";
import { EmployeeRole } from "@/types/v2";
import { authActions } from "@/services/auth";

export const user = {
  getById: async (id: string) => {
    return firestore.getDocument<User>(`users/${id}`);
  },
  create: async (user: Partial<User>) => {
    return firestore.createDocument(`users/${user.id}`, user);
  },
  update: async (user: Partial<User>) => {
    return firestore.updateDocument(`users/${user.id}`, user);
  },
  subscribe: (id: string, callback: (doc: User | undefined) => void) => {
    return firestore.subscribeDocument<User>(`users/${id}`, callback);
  },
  getLinkedShops: async (id: string, role: Role) => {
    // Attach shops that user has access to
    let allowedShops;
    if (role === "superAdmin") {
      allowedShops = await shop.listShops();
    } else {
      allowedShops = await shop.listShopsByUser(id);
    }
    return allowedShops.filter((shop) => !shop.disabled);
  },
  agreeToTerms: async (id: string) => {
    await user.update({
      id: id,
      acceptedTermsAt: new Date(),
      acceptedTermsVersion: TERMS_VERSION,
    });
  },
  getId: (email: string) => {
    return httpsCallables.getAuthUser({ email });
  },
  createAuthAccount: async (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    return httpsCallables.createAuthUser({
      email,
      displayName: `${firstName} ${lastName}`,
    });
  },
  deactivateAuthAccount: (email: string) => {
    return httpsCallables.deactivateAuthUser({ email });
  },
  updateUserRole: ({
    userId,
    userRole,
    userRoleEnabled,
    userShopId,
    disableUser,
  }: {
    userId: string;
    userRole?: EmployeeRole;
    userRoleEnabled?: boolean;
    userShopId?: string;
    disableUser?: boolean;
  }) => {
    return httpsCallables.updateUserClaims({
      userId,
      userRole,
      userRoleEnabled,
      userShopId,
      disableUser,
    });
  },
  getUserShopRole: async (
    shopId: string
  ): Promise<EmployeeRole | "superAdmin" | undefined> => {
    const claims = await authActions.getUserClaims();
    if (!claims) return undefined;

    if (claims.superAdmin) return "superAdmin";

    const shopClaims = claims.shops ? claims.shops[shopId] : undefined;

    if (shopClaims && shopClaims.admin) {
      return "admin";
    } else if (shopClaims && shopClaims.office) {
      return "office";
    } else if (shopClaims && shopClaims.technician) {
      return "technician";
    } else if (shopClaims && shopClaims.viewOnly) {
      return "viewOnly";
    }
    // TODO: RESET TO viewOnly later
    return "admin";
  },
};
